import React from 'react'
import moment from 'moment';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { formatDate } from '../../helper/formatDate';

const EventDetailsSection = ({ eventDetails, type, onEditClick }) => {
    const date = type === "feis" ? eventDetails?.feis_date : eventDetails?.exam_date;
    return (
        <div className="col-lg-6 mainDivLive">
            <span className="eventDetailsBlock">Event Details</span>
            <BorderColorIcon className="editIcon" onClick={onEditClick} />
            <div className="eventsHr" style={{ marginBottom: '17px', height: '1.1px' }}></div>
            <div className=" abountEventLive">
                <div className="dummy">
                    <div className="eventDetailsBlock mt-1" style={{ fontSize: '16px' }}> About event</div>

                    <div className="detailsHeading">{type === "feis" ? "Name of Feis:" : "Name of Exam:"}</div>
                    <span className="LiveData">{eventDetails?.name}</span>

                    <div className="detailsHeading">{type === "feis" ? "Feis Contact Email:" : "Exam Contact Email:"}</div>
                    <span className="LiveData">{eventDetails?.contact_email}</span>

                    <div className="detailsHeading">Region:</div>
                    <span className="LiveData">{eventDetails?.region}</span>

                    <br />
                    <div className="eventDetailsBlock mt-2" style={{ fontSize: '16px' }} >Options</div>

                    <div className="detailsHeading">Cap:</div>
                    <span className="LiveData">{eventDetails?.cap?.capType}</span>

                    {eventDetails?.cap?.levelCap?.length > 0 && (
                        <>
                            <div className="detailsHeading">Level Cap:</div>
                            <span className="LiveData">{eventDetails?.cap?.levelCap}</span>
                        </>
                    )}

                    {eventDetails?.cap?.value && (
                        <>
                            <div className="detailsHeading">Value:</div>
                            <span className="LiveData">{' '}{eventDetails?.cap?.value}</span>
                        </>
                    )}

                    {eventDetails?.cap?.grades && eventDetails?.cap?.capType == 'Level Cap' && (
                        <>
                            <div className="detailsHeading">Grades:</div>
                            <div className="sublevel">(beginner, advanced beginner,novice, prize-winner)</div>
                            <span className="LiveData">{eventDetails?.cap?.grades}</span>
                        </>
                    )}

                    {eventDetails?.cap?.champ && eventDetails?.cap?.capType == 'Level Cap' && (
                        <>
                            <div className="detailsHeading">Champ:</div>
                            <div className="sublevel">(prelim and open champ)</div>
                            <span className="LiveData">{eventDetails?.cap?.champ}</span>
                        </>
                    )}
                </div>
                <div className="dummy2">
                    <div className="eventDetailsBlock mt-1" style={{ fontSize: '16px' }}>Event date</div>

                    <div className="detailsHeading">{type === "feis" ? "Feis Date:" : "Exam Date:"}</div>
                    <span className="LiveData">{moment(formatDate(date)).format('MM/DD/YYYY')} </span>

                    <div className="detailsHeading">Reg. Open Date:</div>
                    <span className="LiveData">{moment(formatDate(eventDetails?.opening_date)).format('MM/DD/YYYY')}</span>

                    <div className="detailsHeading">Reg. Close Date:</div>
                    <span className="LiveData">{moment(formatDate(eventDetails?.closing_date)).format('MM/DD/YYYY')}</span>
                </div>
            </div>
        </div>
    )
}

export default EventDetailsSection