import React from 'react'
import { Field, ErrorMessage, } from 'formik';
import { handleWheel, handleKeyDown } from '../../../../helper/handleActions';


const FamilyFeeAndMax = ({ formik, familyFee }) => {

    return (
        <>
            <div className={formik.errors.familyfee && formik.touched.familyfee ? '' : 'input-control'}>
                <label className={formik.errors.familyfee && formik.touched.familyfee ? 'label-head-org-error' : 'label-head-org'}>
                    Family Fee:
                </label>
                <br />
                <Field
                    className={formik.errors.familyfee && formik.touched.familyfee ? 'input-box-org-error' : 'input-box-org'}
                    onWheel={handleWheel}
                    onKeyDown={handleKeyDown}
                    type="number"
                    name="familyfee"
                    placeholder="Family Fee"
                    step="any"
                    
                    onKeyPress={(e) => {
                        if (e.key === 'e') {
                            e.preventDefault();
                        }
                    }}
                    values={familyFee?.familyfee}
                />
                <ErrorMessage className="auth-error" name="familyfee" component="div" />
            </div>
            <div className={formik.errors.familymax && formik.touched.familymax ? '' : 'input-control'}>
                <label
                    className={formik.errors.familymax && formik.touched.familymax ? 'label-head-org-error' : 'label-head-org'}>
                    Family max:
                </label>
                <br />
                <Field
                    className={formik.errors.familymax && formik.touched.familymax ? 'input-box-org-error' : 'input-box-org'}
                    type="number"
                    name="familymax"
                    onWheel={handleWheel}
                    onKeyDown={handleKeyDown}
                    placeholder="Family max:"
                    step="any"
                    onKeyPress={(e) => {
                        if (e.key === 'e') {
                            e.preventDefault();
                        }
                    }}
                    
                />
                <ErrorMessage className="auth-error" name="familymax" component="div" />
            </div>
        </>
    )
}

export default FamilyFeeAndMax
