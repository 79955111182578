import React, { useEffect, useRef, useState } from 'react';
import Modal from '@mui/material/Modal';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import Box from '@mui/material/Box';
import closeIcon from '../../../Assets/close-icon.svg';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { serviceConsumer } from '../../../network/ServiceConsumer';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MODAL } from '../../../redux/actionTypes/modalActionType';
import { getAddressObject } from '../../../helper/getAddressObject';
import LoadScript from '../../Maps/LoadScript';
import { Form, Formik, useFormikContext, label, Field, ErrorMessage, } from 'formik';
import Loader from '../../Loader/Loader';
import { accValidationSchema } from '../../../helper/Validations';
import { handlePhotoUpload } from '../../../helper/handleUploads';
import CustomInput from './CustomInput';
import AddButton from '../../AddButton/AddButton';

const AccomodationModel = ({ modalState, action, showAdjucator, setaccomodationdata, accomodationData, from, isManage, }) => {
    const formik = useFormikContext();
    const dispatch = useDispatch();
    const modal = useSelector((state) => state.modalReducer);
    const [showList, setShowList] = useState(false);
    const [imgError, setImgErr] = useState('');
    const [logo, setLogo] = useState('');
    const [upl, setUpl] = useState('');
    const [value, setValue] = useState('');
    const [lat, setLat] = useState();
    const [lon, setLon] = useState();

    const [loading, setLoading] = useState(false);
    const [adress, setAddress] = useState();
    const [text, setText] = useState('');

    let EventID = isManage ? modal.eventId : localStorage.getItem('ID');

    const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading, } = useGoogle(
        {
            apiKey: process.env.REACT_APP_GOOGLE_API,
            options: { componentRestrictions: { country: ['ca', 'USA'] }, },
        });

    const getDetails = (placeid, formik) => {
        const data = placePredictions.filter((item) => item.place_id === placeid);

        placesService?.getDetails(
            { placeId: data[0].place_id, },
            (placeDetails) => {
                setLat(placeDetails.geometry.location.lat().toString());
                setLon(placeDetails.geometry.location.lng().toString());

                const address = getAddressObject(placeDetails?.address_components);
                setValue(placeDetails?.name);

                formik.setFieldTouched('address', false);
                formik.setFieldValue('address', address?.city + ' ' + address?.region + ' ' + address?.country + ' ' + address?.postal_code + ' ');
                formik.setFieldValue('city', address?.city);
                formik.setFieldValue('state', address?.region);
                formik.setFieldValue('country', address?.country);
                formik.setFieldValue('postal_code', address?.postal_code);
                formik.setFieldTouched('postal_code', address?.city);
                formik.setFieldTouched('postal_code', address?.region);
                formik.setFieldTouched('postal_code', false);

                setAddress({
                    address: placeDetails?.name,
                    city: address?.city,
                    province: address?.region,
                    postalCode: address?.postal_code,
                    lat: placeDetails.geometry.location.lat().toString(),
                    lon: placeDetails.geometry.location.lng().toString(),
                });

                setShowList(false);

                return formik.isValid
            }
        );
    };

    const addAcomodationInitialValues = {
        name: '',
        address: '',
        city: '',
        state: '',
        country: '',
        url: '',
        photo: '',
        postal_code: '',
        description: '',
    };

    useEffect(() => {
        setAddress(formik?.values?.address);
    }, [formik?.values]);

    const addAcomodationValidationSchema = accValidationSchema

    const addAcomodationHandler = async (values, formikHelpers) => {
        const payload = {
            name: values.name,
            photo: logo,
            description: text,
            address: values.address,
            country: values.country,
            postal_code: values.postal_code,
            url: values.url,
            eventId: EventID,
            city: values.city,
            state: values.state,
            latitude: lat,
            longitude: lon,
        };
        setLoading(true);
        try {
            setLoading(true);
            const url =
                from !== 'grade'
                    ? `${process.env.REACT_APP_BASE_URL}/feis/accomdations`
                    : `${process.env.REACT_APP_BASE_URL}/grade-exam/accomdations`;

            const response = await serviceConsumer('POST', url, payload);
            if (response.success === true) {
                setLoading(false);
                isManage ? openAccListHandler() : action();
                accomodationData()
                setaccomodationdata(true);
            }
            action();
        } catch (error) {
            action();
            setLoading(false);
        }
    };

    const imgInputRef = useRef(null);

    const handleFileSelect = (event, form) => handlePhotoUpload(event, setLoading, setLogo, setUpl, setImgErr, form,true)

    const handleLOgo = () => {
        imgInputRef.current.click();
    };

    const modalCloseHandler = () => {
        dispatch({
            type: SET_MODAL,
            payload: {
                modal: '',
                eventId: '',
            },
        });
    };

    const openAccListHandler = () => {
        dispatch({
            type: SET_MODAL,
            payload: {
                modal: 'Accomodation',
                eventId: modal.eventId,
            },
        });
    };

    // to maintain 500 word limit
    const handleTextChange = (event, formik) => {
        const enteredText = event.target.value;
        const words = enteredText.trim().split(/\s+/);
        if (words.length <= 500) {
            formik.setFieldValue('description', enteredText);
            setText(enteredText);
        }
    };
    const getClass = (error, touched, errorClass, defaultClass) => {
        if (error && touched) {
            return errorClass
        } else return defaultClass
    }
    return (
        <div style={{ padding: '100px', overflow: 'scroll' }}>
            {loading && <Loader />}
            {showAdjucator === false && (
                <Modal
                    className="addDancerScroll"
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    open={isManage ? true : modalState}
                >
                    <Box>
                        <div style={{ maxHeight: '100vh', padding: '5px 100vw', overflow: 'scroll', }}>
                            <div className="main-div" >
                                <div className="close-div mt-4 mb-1">
                                    <img src={closeIcon} alt="close-icon" className="close-icon" onClick={() => { isManage ? modalCloseHandler() : action(); }} />
                                </div>
                                <p className="color-subtitle text-center mb-4" style={{ marginTop: '16px' }}>Add Accomodation</p>
                                <div className="d-flex align-items-center justify-content-center" style={{ background: 'white,borderRadius: 14px;' }}>
                                    <Formik
                                        initialValues={addAcomodationInitialValues}
                                        validationSchema={addAcomodationValidationSchema}
                                        onSubmit={addAcomodationHandler}
                                        validateOnChange={true}
                                        validateOnBlur={true}
                                    >
                                        {(formik) => (
                                            <Form className="form form-label-right" autoComplete="off">
                                                <input type="file" ref={imgInputRef} style={{ display: 'none' }} onChange={(e) => handleFileSelect(e, formik)} />

                                                <CustomInput label="Name Accomodation" name="name" type="text" placeholder="Name" formik={formik} />

                                                <div className={getClass(formik.errors.address, formik.touched.address, 'd-grid', 'input-control d-grid')}>
                                                    <label className={getClass(formik.errors.address, formik.touched.address, 'label-head-org-error', 'label-head-org')}>
                                                        Address:
                                                    </label>
                                                    <Field
                                                        className={getClass(formik.errors.address, formik.touched.address, 'input-box-org-error', 'input-box-org')}
                                                        type="text"
                                                        name="address"
                                                        placeholder="Address"
                                                        value={value}
                                                        onChange={(evt) => {
                                                            setValue(evt.target.value);
                                                            setAddress(null);
                                                            getPlacePredictions({ input: evt.target.value });
                                                            if (!isPlacePredictionsLoading) setShowList(true);
                                                            formik.setFieldValue('address', evt.target.value);
                                                        }}
                                                    />
                                                    {value !== '' && showList && placePredictions.length > 0 && (
                                                        <div className="google-autosuggestion-list">
                                                            <ListGroup dataSource={placePredictions}>
                                                                {placePredictions.map((item) => (
                                                                    <ListGroupItem key={item?.place_id} onClick={() => getDetails(item?.place_id, formik)}>
                                                                        {item.description}
                                                                    </ListGroupItem>
                                                                ))}
                                                            </ListGroup>
                                                        </div>
                                                    )}
                                                    <ErrorMessage className="auth-error" name="address" component="div" />
                                                </div>

                                                <CustomInput label="City" name="city" type="text" placeholder="City" formik={formik} />
                                                <CustomInput label=" State/Provice" name="state" type="text" placeholder="State" formik={formik} />
                                                <CustomInput label="Country" name="country" type="text" placeholder="Country" formik={formik} />
                                                <CustomInput label=" Postal /Zipcode" name="postal_code" type="text" placeholder="Postal code" formik={formik} />
                                                {lat && lon && (
                                                    <LoadScript lat={lat} lon={lon} address={formik.values.address} region={formik.values.address} zipcode={formik.values.address} />
                                                )}
                                                <CustomInput label="Add URL" name="url" type="text" placeholder="URL" formik={formik} />

                                                <div className={getClass(formik.errors.description, formik.touched.description, 'd-grid', 'input-control d-grid')}>
                                                    <label className={getClass(formik.errors.description, formik.touched.description, 'label-head-org-error', 'label-head-org')}>
                                                        Description:
                                                    </label>
                                                    <Field
                                                        className={getClass(formik.errors.description, formik.touched.description, 'input-box-org-des-error', 'input-text-accomodation-des')}
                                                        as="textarea"
                                                        name="description"
                                                        placeholder="Description"
                                                        value={text}
                                                        onChange={(e) => handleTextChange(e, formik)}
                                                    />
                                                    <ErrorMessage className="auth-error" name="description" component="div" />
                                                    <span className="input-text-area-modal-length">
                                                        {!text?.length ? 500 : 500 - (text?.trim()?.split(/\s+/)?.length || 0)}
                                                    </span>
                                                </div>

                                                <div className={getClass(formik.errors.name, formik.touched.name, 'd-grid', 'input-control d-grid')}>
                                                    <label className="label-head-org">Photo:</label>
                                                    <div><AddButton onClick={handleLOgo} className="plusIconbtn m-0">Add Photo</AddButton></div>

                                                    <span className="error mt-2" style={{ width: '248px' }}>{imgError}</span>
                                                    {upl && !imgError && (<span className="auth-suc">{upl}</span>)}
                                                </div>
                                                <div className="my-3 d-flex justify-content-center">
                                                    <button className={formik.isValid && formik.dirty && adress ? 'filled mt-0' : 'disabled mt-0'} type="submit">
                                                        Add accommodation
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

export default AccomodationModel;
