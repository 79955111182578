import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import pplIcon from "../../Assets/managefeisGrade/addCompetitor.svg"
import feisEvents from "../../Assets/feisevents.png"
import gradeExams from '../../Assets/school.png'
import { serviceConsumer } from '../../network/ServiceConsumer';
import Loader from '../../Components/Loader/Loader';
import { v4 as uuidv4 } from "uuid"
import HeaderOrg from '../Headers/HeaderOrg'
import "./DashboardOrg.css"

const Dashboard = () => {
    const [loading, setLoading] = useState(false)
    const [countResponse, setCountResponse] = useState({})

    const navigate = useNavigate()

    const iconArr = [pplIcon, pplIcon, feisEvents, gradeExams, pplIcon]

    useEffect(() => { getCount() }, [])

    const getCount
        = async () => {
            try {
                setLoading(true);
                const url = `${process.env.REACT_APP_BASE_URL}/api/admin/dashboard/count`
                const response = await serviceConsumer('GET', url);
                const seggregatedResponse = {
                    "Total number of Users": response?.result?.users + response?.result?.organizers,
                    "Total number of Feis Events": response?.result?.feis,
                    "Total number of Grade Exams": response?.result?.gradeexams,
                    "Total number of Schools": response?.result?.schoolCount,
                    "Total number of Collaborators": response?.result?.peopleCount
                }
                setCountResponse(seggregatedResponse)
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

    const redirectToUsersPage = (cardName) => {
        if (cardName === "Users") navigate("/users")
        if (cardName === "Schools") navigate("/schools")
        if (cardName === "Feis") navigate("/feis")
        if (cardName === "Grade") navigate("/grade")
        if (cardName === "Collaborators") navigate("/Collaborators")
    }

    return (
        <>
            {loading && <Loader />}
            <HeaderOrg />
            <div className="db-container">
                <p className="hi-name mb-0">Hi, Admin!</p>
                <div className="db-tab-container"></div>
                {!loading && <div className="db-body py-2">
                    <div className="db-org-title pt-4">Dashboard</div>
                    <div className="mt-1 mb-4 hr-org-body" />

                    <div className="dashboard-box">
                        <div className="admin-db-list">
                            {Object.keys(countResponse).map((item, index) => {
                                // only the user card has a cursor and redirection
                                const cardName = item.split(" ")[3]
                                return <div key={uuidv4()} onClick={() => redirectToUsersPage(cardName)} className="selectable-admin-db-card" >
                                    <img className="admin-img" src={iconArr[index]} alt="" />
                                    <div>
                                        <div className="admincard-head">{countResponse[item]}</div>
                                        <div className="admincard-descr mt-1">{item}</div>
                                    </div>
                                </div>
                            }
                            )}
                        </div>
                    </div>
                </div>}
            </div>

        </>
    )
}

export default Dashboard;