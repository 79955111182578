import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
import Loader from '../../Components/Loader/Loader';
import { serviceConsumer } from '../../network/ServiceConsumer';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { accessTillEventDetails, accessTillVenueDetails } from '../../helper/CreateDispatches';

import { createEventDetailValidationSchema } from '../../helper/Validations';
import AboutEvent from './Components/EventDetailsComp/AboutEvent';
import EventDate from './Components/EventDetailsComp/EventDate';
import CapOptions from './Components/EventDetailsComp/CapOptions';
import { formatDate } from '../../helper/formatDate';

const EventDetailsFies = (props) => {
  const [levelCap, SetlevelCap] = useState(false);
  const [eventDetails, setEventDetails] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [updateApi, setupdateApi] = useState(false);
  const [isLive, setIsLive] = useState(false)
  let EventID = localStorage.getItem('ID');
  const TAB = localStorage.getItem('TAB');
  const page = localStorage.getItem('page');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search)
  const previousEvent = searchParams.get('previousEvent')
  const organizerId = localStorage.getItem("organizerId") ? localStorage.getItem("organizerId") : ""


  //  to get eventDetails on back
  const getEventDetails = async () => {
    try {
      setLoading(true);
      if (EventID) {
        const url = props.type === "feis"
          ? `${process.env.REACT_APP_BASE_URL}/api/admin/feis/${EventID}`
          : `${process.env.REACT_APP_BASE_URL}/api/admin/grade/${EventID}`;
        const response = await serviceConsumer('GET', url);
        const res = props.type === "feis" ? response.Feis : response.grade_exams

        setEventDetails(res);
        setIsLive(response?.Feis?.live)
        if (!previousEvent) {
          localStorage.setItem('page', res?.page);
        }
        if (response.success) {
          setupdateApi(true);
          dispatchFunction(false, dispatch);
          if (response?.Feis?.cap?.capType == 'Level Cap') {
            SetlevelCap(true);
          }
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const EventDetailsInitialValues = props.type === "feis"
    ? generateEventDetailsInitialValues(eventDetails, ['feis_date', 'opening_date', 'closing_date'])
    : generateEventDetailsInitialValues(eventDetails, ['exam_date', 'opening_date', 'closing_date']);

  // validation
  const eventDetailsValidationSchema = props.type === "feis"
    ? createEventDetailValidationSchema('feis_date', 'Feis date must be greater than the closing date.')
    : createEventDetailValidationSchema('exam_date', 'Exam date must be greater than the closing date.');

  useEffect(() => {
    getEventDetails();
    page > TAB && dispatchFunction(false, dispatch);
  }, []);


  const putUrl = props.type === "feis" ? `${process.env.REACT_APP_BASE_URL}/feis/${EventID}` : `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}`;
  const postUrl = props.type === "feis" ? `${process.env.REACT_APP_BASE_URL}/feis` : `${process.env.REACT_APP_BASE_URL}/grade-exam`;
  const navigateToView = () => props.type === "feis" ? navigate('/feis/create') : navigate('/grade/create')

  const setNameAndTabValue = (response, values) => {
    if (response.success === true) {
      props.setEventName(values.name)
      setTimeout(() => { props.setValue('2'); }, 300);
      setLoading(false);
    }
  }

  const setEventNameForNewEvent = (response, values) => {
    if (response.success === true) {
      props.setEventName(values.name)
      localStorage.setItem('ID', props.type === "feis" ? response.feis._id : response.grade_exam._id);
      localStorage.setItem('TAB', 2);
      if (Number(localStorage.getItem('page')) < 1) {
        localStorage.setItem('page', 1);
      }
      setTimeout(() => {
        props.setValue('2');
        if (previousEvent) {
          navigateToView()
          localStorage.setItem('previousEvent', true);
        }
        dispatchFunction(true, dispatch);
        setLoading(false);
      }, 300);
    }
  }
  // submitHandler
  const EventDetalsHandler = async (values, formik) => {
    let dateModifiedValues = {
      ...values,
      organizer_id: organizerId,
      opening_date: moment(values.opening_date).format("yyyy-MM-DD"),
      closing_date: moment(values.closing_date).format("yyyy-MM-DD"),
      ...(props.type === "feis" ? { feis_date: moment(values.feis_date).format("yyyy-MM-DD") } : { exam_date: moment(values.exam_date).format("yyyy-MM-DD") }),
      ...(props.type === "feis" ? { eventDate: moment(values.feis_date).format("yyyy-MM-DD") } : { eventDate: moment(values.exam_date).format("yyyy-MM-DD") }),
    };

    if (updateApi && !previousEvent) {
      try {
        checkClosingIsBeforeAfterDate(eventDetails, values.closing_date, formik);
        setLoading(true);
        const url = putUrl
        const response = await serviceConsumer('PUT', url, dateModifiedValues);
        setNameAndTabValue(response, values)

      } catch (error) {
        setLoading(false);
      }
    } else {
      try {
        checkClosingIsBeforeAfterDate(eventDetails, values.closing_date, formik)
        setLoading(true);
        const prev = { ...eventDetails };
        delete prev['_id'];
        let value = { ...(previousEvent ? { ...prev, previous: true, organizer_id: organizerId } : {}), ...dateModifiedValues, page: 1, live: false, organizer_id: organizerId };
        const url = postUrl
        const response = await serviceConsumer('POST', url, value);
        setEventNameForNewEvent(response, values)
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {loading && <Loader />}
      {!loading && (
        <Formik
          initialValues={EventDetailsInitialValues}
          validationSchema={eventDetailsValidationSchema}
          onSubmit={EventDetalsHandler}
          validateOnChange
          validateOnBlur
        >
          {(formik) => (
            <Form className="form form-label-right" autoComplete="off">
              <>
                <div className="detailsHeaders">
                  <h4 className="eventPage">Event Details</h4>
                  <p className="eventDescription">Complete these details to create event.</p>
                </div>
                <div className="eventSection">
                  <AboutEvent formik={formik} props={props} isLive={isLive} previousEvent={previousEvent} eventDetails={eventDetails} />
                  <EventDate formik={formik} props={props} />
                </div>
                <CapOptions formik={formik} props={props} levelCap={levelCap} SetlevelCap={SetlevelCap} />
                <div className="mt-3 mb-5" >
                  <button
                    className={getDisabled(formik, props.type).isValid && formik.dirty ? 'filled' : 'disabled'}
                    type="submit"
                    disabled={getDisabled(formik, props.type).disabled}
                  >
                    Next
                  </button>
                </div>
              </>
            </Form>
          )}
        </Formik>
      )}
      <ToastContainer />
    </div>
  );
};

export default EventDetailsFies;

const getCapValues = (formik) => {
  if (formik.values.cap?.capType == 'Global Cap') return formik.values.cap?.value
  else if (formik.values.cap?.capType == 'Level Cap') return (formik.values.cap?.grades || formik.values.cap?.champ)
  else return true
}

const getDisabled = (formik, type) => {
  const isValid = !!(
    formik.values.name &&
    (formik.values.region && formik.values.region != 'Select') &&
    formik.values.contact_email &&
    !formik.errors.contact_email &&
    formik.values.opening_date &&
    formik.values.closing_date &&
    formik.values.closing_date >= formik.values.opening_date &&
    (type === "feis" ? formik.values.feis_date : formik.values.exam_date) >= formik.values.closing_date &&
    (type === "feis" ? formik.values.feis_date : formik.values.exam_date) &&
    (type === "feis" ? formik.values.feis_date : formik.values.exam_date) &&
    (formik.values.cap?.capType && formik.values.cap?.capType != 'Select') &&
    (getCapValues(formik))
  );

  const disabled = !isValid && !formik.dirty && formik.isSubmitting;
  return { disabled, isValid };
};



const generateEventDetailsInitialValues = (eventDetails, dateFields) => {
  const initialValues = {
    name: eventDetails?.name || '',
    contact_email: eventDetails?.contact_email || '',
    region: eventDetails?.region || '',
    cap: {
      capType: eventDetails?.cap?.capType || '',
      GlobalCap: eventDetails?.cap?.GlobalCap || '',
      value: eventDetails?.cap?.value || '',
      grades: eventDetails?.cap?.grades || '',
      champ: eventDetails?.cap?.champ || '',
    },
    percentageToAward: eventDetails?.percentageToAward || '',
    minimumNumberToAward: eventDetails?.minimumNumberToAward || '',
  };

  dateFields.forEach((field) => {
    initialValues[field] = eventDetails?.[field] && formatDate(eventDetails?.[field]);
  });

  return initialValues;
};

const dispatchFunction = (next, dispatch) => {
  next ? accessTillEventDetails(dispatch) : accessTillVenueDetails(dispatch);
};

const convertTZ = (date) => {
  const newDate = moment(date).format('YYYY/MM/DD');
  return newDate ? new Date(newDate) : date;
}

const checkClosingIsBeforeAfterDate = (eventDetails, closingDate, formik) => {
  if (eventDetails?.fees && eventDetails?.fees[0]?.lateFee?.afterDate) {
    toast.dismiss()
    const afterDate = new Date(eventDetails.fees[0]?.lateFee?.afterDate);
    if (afterDate && new Date(afterDate) < convertTZ(closingDate)) {
      return true;
    } else {
      toast.error('Late fee date must be less than the updated closing date. To Continue, please make the changes in Fees Tab');
      return false
    }
  } else return true;
}

