import React from 'react'
import { useDispatch } from 'react-redux';
import { accessTillFees } from '../../helper/CreateDispatches';
import { serviceConsumer } from '../../network/ServiceConsumer';

const AddStripeAccount = ({ cards, seterrorconn, createOrEdit, setLoading, eventType }) => {
    const dispatch = useDispatch()

    const linkStripe = async (e) => {
        e.preventDefault()
        let type
        if (createOrEdit) {
            if (eventType === "feis") type = "event"
            else type = "grade"
        } else type = "stripeaccount"
        try {
            setLoading(true)
            const url = `${process.env.REACT_APP_BASE_URL}/stripe/admin/get-link-url?type=${type}`
            const response = await serviceConsumer("GET", url)
            if (response.success === true) {
                window.location.href = response?.result?.url;
                createOrEdit && accessTillFees(dispatch)
            }
        } catch (err) {
            console.log(err)
        }
        setLoading(false)
    }

    return (
        <div className="d-flex gap-2">
            <button className={cards?.length < 3 ? "filled m-0" : "disabled m-0"} onClick={linkStripe} >Add/Link Account</button>
        </div>
    )
}

export default AddStripeAccount
