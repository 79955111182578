import React from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import { CalculateCount } from '../../../../helper/CalculateCount'
import { v4 as uuidv4 } from "uuid"

const OneJudgeResult = ({ rounds, results, keyProp, event }) => {
    const countOfTopPlaces = event && CalculateCount(event, results)
    const getClassName = (index) => {
        if (index === countOfTopPlaces - 1) return "increasedBorderBottom"
        else return "hi"
    }
    return (
        <div key={keyProp}>
            <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px", marginBottom: "50px" }}>
                <table className="table my_table competitorTable ">
                    <tbody>
                        <tr className="header-row">
                            <td className="text-center p-25-5 w-11">Award Received</td>
                            <td className="text-center p-25-5 w-10">#</td>
                            <td className="text-center p-25-5 w-15">Final Place</td>
                            <td className="mx-5 p-25-5 w-25">Competitor</td>
                            <td className="text-center p-25-5 w-50">{results[0]?.scores[0]?.judge_details?.name ? results[0]?.scores[0]?.judge_details?.name : "-"}</td>
                        </tr>
                        {rounds === 1 && results?.map((dancer, index) => {
                            return <tr className={getClassName(index)} key={uuidv4()} style={{ background: "#FFFFFF", fontSize: "12px", height: "31px" }}>
                                <td className="text-center mx-5 py-4 w-11 grey-30"><Checkbox /></td>
                                <td className="text-center mx-5 py-4 w-10 grey-30">{dancer?.card_number?.card_number}</td>
                                <td className="text-center mx-5 py-4 w-15 grey-30">{dancer?.overallPosition}</td>
                                <td className="mx-5 w-25 grey-30"><div>{dancer?.dancer_fname}&nbsp;{dancer?.dancer_lname} <div style={{ opacity: "0.3" }}>{dancer?.school}</div></div></td>
                                <td className="w-50 grey-30 text-center p-25-5">{dancer?.scores[0]?.scores_by_round[0]?.score ? dancer?.scores[0]?.scores_by_round[0]?.score : "-"}</td>
                            </tr>
                        })}
                        {rounds === 2 && <>
                            <tr style={{ background: "#EAEAEA", fontSize: "14px", height: "31px" }}>
                                <td className="text-center grey-42 p-25-5 w-11"></td>
                                <td className="text-center grey-42 p-25-5 w-5"></td>
                                <td className="text-center mx-5 py-4 w-10 grey-30"></td>
                                <td className="mx-5 grey-42 p-25-5 w-25"></td>
                                <td className="text-center p-25-5 grey-42 w-50">
                                    <div className="d-flex justify-content-center gap-1">
                                        <div className="col-2">1</div>
                                        <div className="col-2">2</div>
                                        <div className="col-2">Place</div>
                                    </div>
                                </td>
                            </tr>
                            {results?.map((dancer, index) => {
                                return <tr key={uuidv4()} style={{ background: "#FFFFFF", fontSize: "12px", height: "31px" }}>
                                    <td className="text-center mx-5 py-4 w-11 grey-30"><Checkbox /></td>
                                    <td className="text-center mx-5 py-4 w-5 grey-30">{dancer?.card_number?.card_number}</td>
                                    <td className="text-center mx-5 py-4 w-7 grey-30">{dancer?.overallPosition}</td>
                                    <td className="mx-5 w-25 grey-30"><div>{dancer?.dancer_fname}&nbsp;{dancer?.dancer_lname}<div style={{ opacity: "0.3" }}>{dancer?.school}</div></div></td>
                                    <td className="text-center w-50 grey-30">
                                        <div className="d-flex justify-content-center gap-1">
                                            <div className="col-2">{dancer?.scores[0]?.scores_by_round[0]?.score ? dancer?.scores[0]?.scores_by_round[0]?.score : "-"}</div>
                                            <div className="col-2">{dancer?.scores[0]?.scores_by_round[1]?.score ? dancer?.scores[0]?.scores_by_round[1]?.score : "-"}</div>
                                            <div className="col-2">{dancer?.scores[0] ? dancer?.scores[0]?.position : "-"}</div>
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </>}
                        {rounds === 3 && <> <tr style={{ background: "#EAEAEA", fontSize: "14px", height: "31px" }}>
                            <td className="text-center grey-42 p-25-5 w-11"></td>
                            <td className="text-center grey-42 p-25-5 w-5"></td>
                            <td className="text-center mx-5 py-4 w-10 grey-30"></td>
                            <td className="mx-5 grey-42 p-25-5 w-25"></td>
                            <td className="grey-42 p-25-5 w-50 text-center">
                                <div className="d-flex justify-content-center gap-1">
                                    <div className="col-2">1</div>
                                    <div className="col-2">2</div>
                                    <div className="col-2">3</div>
                                    <div className="col-2">Place</div>
                                </div>
                            </td>
                        </tr>
                            {results?.map((dancer, index) => {
                                return <tr key={uuidv4()} style={{ background: "#FFFFFF", fontSize: "12px", height: "31px" }}>
                                    <td className="text-center mx-5 py-4 w-11 grey-30"><Checkbox /></td>
                                    <td className="text-center mx-5 py-4 w-5 grey-30">{dancer?.card_number?.card_number}</td>
                                    <td className="text-center mx-5 py-4 w-7 grey-30">{dancer?.overallPosition}</td>
                                    <td className="mx-5 w-25 grey-30"><div>{dancer?.dancer_fname}&nbsp;{dancer?.dancer_lname}<div style={{ opacity: "0.3" }}>{dancer?.school}</div></div></td>
                                    <td className="grey-42 p-25-5 w-50 text-center">
                                        <div className="d-flex justify-content-center gap-1">
                                            <div className="col-2">{dancer?.scores[0]?.scores_by_round[0]?.score ? dancer?.scores[0]?.scores_by_round[0]?.score : "-"}</div>
                                            <div className="col-2">{dancer?.scores[0]?.scores_by_round[1]?.score ? dancer?.scores[0]?.scores_by_round[1]?.score : "-"}</div>
                                            <div className="col-2">{dancer?.scores[0]?.scores_by_round[2]?.score ? dancer?.scores[0]?.scores_by_round[2]?.score : "-"}</div>
                                            <div className="col-2">{dancer?.scores[0] ? dancer?.scores[0]?.position : "-"}</div>
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </>}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default OneJudgeResult