import React from 'react'
import Button from '../Button/Button'
import moment from 'moment'
import favicon from "../../Assets/favicon.ico"
import "./Card.css"
import { formatDate } from '../../helper/formatDate'

const Card = (props) => {
  const { logo, eventType, eventName, date, venue, state, onClick, btnName, keyProp, orgName, from } = props;
  return (
    <div key={keyProp} className={from === "closed" ? "card-events no-pointer-events" : "card-events"} onClick={onClick}>
      <div className="card-events-web gap-3">
        <img src={logo || favicon} className="card-image" alt="logo" />
        <div className="flex-grow-1">
          <p className="card-title m-0">{eventType === "Feiseanna" ? "Feisanna" : "Grade Exam"}</p>
          <p className="card-event-name m-0">{eventName} </p>
          <p className="card-date mt-2 mb-0">{moment(formatDate(date)).format(" MMMM Do, YYYY")}- {venue}, {state}</p>
        </div>
        <div className="d-flex">
          {orgName && <div className="color-primary d-flex align-items-center mx-5">{orgName}</div>}
          {btnName && <Button className="outlined" onClick={onClick}>{btnName}</Button>}
        </div>

      </div>
      <div className="card-mobile" onClick={onClick}>
        <div className="card-mobile-div gap-2">
          <img src={logo || favicon} className="card-image" alt="logo" />
          <div>
            <p className="card-title m-0">{eventType === "Feiseanna" ? "Feisanna" : "Grade Exam"}</p>
            <p className="card-event-name m-0">{eventName || "NA"}</p>
            <p className="card-date mt-2 mb-0">{moment(formatDate(date)).format(" MMMM Do, YYYY")}- {venue}, {state}</p>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between mx-3" >
          {orgName ? <div className="color-primary d-flex align-items-center mx-1">{orgName}</div> : <div></div>}
          {btnName && <Button className="outlined outlined-mob" onClick={onClick}>{btnName}</Button>}
        </div>
      </div>
    </div>
  )
}

export default Card;