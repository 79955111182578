import React from 'react';
import { Field, ErrorMessage } from 'formik';
import FormikControl from '../../../../Components/formik/FormikControl';
import { regionData } from '../../../../helper/DataArrays';

const AboutEvent = ({ formik, props, isLive, previousEvent, eventDetails }) => {
    const getNameLabel = () => {
        if (props.type === "feis") return "Name of Feis:"
        else return "Name of Exam:"
    }
    const getEmailLabel = () => {
        if (props.type === "feis") return "Feis Contact Email:"
        else return "Exam Contact Email"
    }
    const getNameInputError = () => {
        if (formik.errors.name && formik.touched.name) return ""
        else return "input-control"
    }
    const getNameLabelError = () => {
        if (formik.errors.name && formik.touched.name) return 'label-head-org-error'
        else return "label-head-org"
    }
    const getNameFieldError = () => {
        if (formik.errors.name && formik.touched.name) return 'input-box-org-error'
        else return 'input-box-org'
    }

    const getNameError = () => {
        if (formik.errors.name && formik.touched.name) return 'auth-error'
        else return ''
    }

    const getEmailInputError = () => {
        if (formik.errors.contact_email && formik.touched.contact_email) return ''
        else return 'input-control'
    }

    const getRegionClass = () => {
        if (isLive && !previousEvent) return "input-box-org-select-disabled"
        else if (formik.errors.region && formik.touched.region) return 'input-box-select-error '
        else return 'input-box-org-select'
    }
    return (
        <div className="Aboutevent">
            <h4 className="eventPage mt-md-5 ">About event</h4>
            <div className="d-flex flex-column ">
                <div className={getNameInputError()}>
                    <label className={getNameLabelError()}>
                        {getNameLabel()}
                    </label>
                    <br />
                    <Field
                        className={getNameFieldError()}
                        type="text"
                        name="name"
                        defaultValue={eventDetails?.name}
                        placeholder="Name"
                    />
                    <ErrorMessage className={getNameError()} name="name" component="div" />
                </div>
                <div className={getEmailInputError()}>
                    <label
                        htmlFor="contact_email"
                        className={formik.errors.contact_email && formik.touched.contact_email ? 'label-head-org-error' : 'label-head-org'}>
                        {getEmailLabel()}
                    </label>
                    <br />
                    <Field
                        className={formik.errors.contact_email && formik.touched.contact_email ? 'input-box-org-error' : 'input-box-org'}
                        onBlur={() => { formik.setFieldTouched('contact_email', true); }}
                        type="email"
                        name="contact_email"
                        placeholder="Email"
                    />
                    <ErrorMessage className="auth-error" name="contact_email" component="div" />
                </div>
                <div className={formik.errors.region && formik.touched.region ? '' : 'input-control'}>
                    <label className={formik.errors.region && formik.touched.region ? 'label-select-org-error' : 'label-head-org'}>
                        Region:
                    </label>
                    <FormikControl
                        className={getRegionClass()}
                        control="select"
                        name="region"
                        showAs="Select"
                        options={regionData}
                        id="schoolSelect"
                        setFieldValue={formik.setFieldValue}
                        style={{ fontSize: '12px !important' }}
                        disabled={isLive && !previousEvent}
                    />
                </div>
            </div>
        </div>
    )
}

export default AboutEvent

