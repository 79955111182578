import React, { useState, useEffect } from 'react'
import Modal from "@mui/material/Modal";
import { useDispatch } from 'react-redux';

import closeIcon from "../../../../../Assets/close-icon.svg"
import { openOrCloseModal } from '../../../../../helper/CreateDispatches';
import CustomTimePick from './CustomTimePick';
import { addTimeToComp, calculateCompDuration } from '../../../../../Organizer/ManageFeisGrade/RedirectFeis/Schedule/scheduleHelpers';
import moment from 'moment';


const TimeSetterModal = ({ modalState, competition, tableData, setTableData, isBreakTime, setIsBreakTime, setIsUpdate, isUpdate, startTime, setStartTime, endTime, setEndTime, columnKey }) => {
    const dispatch = useDispatch()
    const dancerCountArr = [1, 2, 3, 4, 5, 6, 7, 8, 9]
    const [rounds, setRounds] = useState(competition?.rounds)
    const [dancers, setDancers] = useState(competition?.dancerCount || [])
    const [timeError, setTimeError] = useState("")

    useEffect(() => {
        if (competition?.code !== "Break Time") {
            const dancerArr = getDancerArray(rounds ? rounds : 1)
            setDancers(dancerArr);
        }
    }, [rounds]);

    useEffect(() => {
        if (competition?.dancerCount?.length > 0) {
            setDancers(competition?.dancerCount)
        }
    }, [competition?.dancerCount])

    useEffect(() => {
        setRounds(competition?.rounds)
    }, [competition])


    const getDancerArray = (rounds) => {
        let dancerArr = [...dancers];
        if (rounds == 1) {
            dancerArr = dancerArr[0] ? [dancerArr[0]] : [{ round: 1, numberOfDancers: 2 }]
        } else {
            if (dancerArr.length === 1) {
                dancerArr.push({ round: 2, numberOfDancers: 2 });
            } else {
                dancerArr = [{ round: 1, numberOfDancers: 2 }, { round: 1, numberOfDancers: 2 }]
            }
        }
        return dancerArr
    }

    const handleSave = () => {
        if (moment(startTime, 'hh:mm A').isSameOrAfter(moment(endTime, 'hh:mm A'))) {
            setTimeError("End time must be greater then Start time");
        } else {
            const updatedTableData = addTimeToComp(competition, startTime, endTime, tableData, columnKey)
            setTableData(updatedTableData)
            modalCloseHandler()
        }
    };

    const handleDancerChange = (e, index) => {
        const dancersCopy = [...dancers]
        dancersCopy[index] = { round: dancersCopy[index].round, numberOfDancers: Number(e.target.value) }
        competition.dancerCount = dancersCopy
        calculateEndTime(competition, startTime)
        setDancers(dancersCopy)
    }

    const handleRoundsChange = (value) => {
        const dancerArr = getDancerArray(value)
        competition.rounds = value
        competition.dancerCount = dancerArr
        calculateEndTime(competition, startTime)
        setDancers(dancerArr);
        setRounds(value)
    }
    const calculateEndTime = (competition, startTime) => {
        const duration = calculateCompDuration(competition)
        const end = moment(startTime, 'hh:mm A').add(duration, 'minutes');
        setEndTime(end)
    }
    const modalCloseHandler = () => {
        openOrCloseModal(dispatch, "")
        setIsBreakTime(false)
        setIsUpdate(false)
    }
    const startTimeChangeHandler = (time) => {
        setStartTime(time)
        calculateEndTime(competition, time)
    }

    const getTitle = () => {
        if (isBreakTime) return "Add Break Time"
        else if (competition?.code === "Break Time") return "Break Time"
        else return `${competition?.code} (${competition?.dancers?.length})`
    }

    const getButtonName = () => {
        if (isBreakTime) return "Add Break"
        else if (isUpdate) return "Update Event"
        else return "Save"
    }
    return (
        <Modal sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }} open={modalState}>
            <div className="org-modal-div py-2 px-5" style={{ width: "349px" }}>
                <div className="close-div mb-1 p-0">
                    <img src={closeIcon} alt="close-icon" className="close-icon" onClick={modalCloseHandler} />
                </div>

                <p className="modal-org-title text-center my-2">{getTitle()}</p>
                <div className="d-flex flex-column">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="w-50 p-2">
                            <label className="search-label" htmlFor="start-time">Start Time:</label>
                            <CustomTimePick selectedTime={startTime} setSelectedTime={(time) => startTimeChangeHandler(time)} setTimeError={setTimeError} type="start" />
                        </div>
                        <div className="w-50 p-2">
                            <label className="search-label" htmlFor="end-time">End Time:</label>
                            <CustomTimePick selectedTime={endTime} setSelectedTime={setEndTime} setTimeError={setTimeError} type="end" />
                        </div>
                        {competition?.code !== "Break Time" && <>
                            <div className="w-50 p-2">
                                <label className="search-label" >No. of Rounds:</label>
                                {/* <input className="time-setter-input" value={rounds} onChange={(e) => setRounds(e.target.value)} /> */}
                                <select className="time-setter-modal-select" onChange={(e) => handleRoundsChange(e.target.value)} value={rounds}>
                                    {[1, 2, 3].map(item => <option value={item} key={item}>{item}</option>)}
                                </select>
                            </div>

                            {dancers?.length > 0 && dancers?.map((dancerCount, index) => <div className="w-50 p-2" key={dancerCount?._id}>
                                <label className="search-label" htmlFor={`round${index}`}>{`Dancers in R${index + 1}`}</label>
                                <select className="time-setter-modal-select" onChange={(e) => handleDancerChange(e, index)} value={dancerCount?.numberOfDancers}>
                                    {dancerCountArr.map(item => <option value={item} key={item}>{item}</option>)}
                                </select>
                            </div>)}
                        </>}
                    </div>
                    {timeError && <div style={{ color: "red", fontSize: "12px", paddingLeft: "5px" }}>{timeError}</div>}
                    <div className="d-flex flex-grow-1 justify-content-center mt-3 mb-5">
                        <button className="filled" onClick={handleSave}>{getButtonName()}</button>
                    </div>

                </div>
            </div>
        </Modal >
    )
}

export default TimeSetterModal
