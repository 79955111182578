import MainDiv from "../../Components/AddedAdjdiv/MainDiv"
import AccomodationDiv from "../../Components/AddedAdjdiv/AccomodationDiv"
import { v4 as uuidv4 } from "uuid"

export const renderCollab = (collabHeading, collabList) => {
    return <>
        <div><span className="detailsHeading  d-grid">{collabHeading}</span></div>
        {collabList.map((collab) =>
            <MainDiv
                name={collab?.person?.name}
                location={collab?.person?.location}
                photo={collab?.person?.photo}
                from={'golive'}
                key={uuidv4()}
            />
        )}
    </>
}

export const renderAccommodation = (accList) => {
    return <>
        <div><span className="detailsHeading  d-grid">Accommodations:</span></div>
        {accList.map((accomodation) => {
            return <AccomodationDiv
                name={accomodation?.name}
                photo={accomodation?.photo}
                country={accomodation?.country}
                from={'golive'}
                id={accomodation?._id}
                key={accomodation?._id}
            />
        })}
    </>
}