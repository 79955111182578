import { serviceConsumer } from "../../network/ServiceConsumer";

export const getCollabdetails = (collaborators,setCollab,promises) => {
    try {
        const collabP = collaborators.map(async (collab) => {
            const url = `${process.env.REACT_APP_BASE_URL}/people/id/${collab}`;
            const collabRes = await serviceConsumer('GET', url);
            return collabRes;
        });

        Promise.all(collabP).then((collabRes) => {
            setCollab(collabRes);
        })

        promises.push(collabP)
    } catch (error) {
    }
}