import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Button from '../../../../Components/Button/Button';
import SingleLineTitle from '../../../../Components/TitleElements/SingleLineTitle';
import Help from '../../../../Components/Help/Help';
import { serviceConsumer } from '../../../../network/ServiceConsumer';
import Loader from '../../../../Components/Loader/Loader';
import HeaderOrg from '../../../Headers/HeaderOrg';
import "../../../../Components/Table.css"
import '../FeisCompetitor.css';
import sort from '../../../../Assets/managefeisGrade/sort-org.svg';
import { postPrintHandler } from '../../../../helper/printHandlers';
import { compTableSearch, getSortOrder } from '../../../../helper/CompTableSearch';
import { groupAndSortComp } from '../../../../helper/SortSyllabus';


const FeisCompetitors = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const eventId = searchParams.get('id');
    const [responseArray, setResponseArray] = useState([]);
    const [tableArr, setTableArr] = useState([]);
    const [sorterData, setSorterData] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [err, setErr] = useState('');

    //get event details for title and competition details for table
    const getAllDetails = async () => {
        try {
            setLoading(true);
            const eventUrl = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`;
            await serviceConsumer('GET', eventUrl);
            const competitionUrl = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}/dancers`;
            const competitionResponse = await serviceConsumer('GET', competitionUrl);
            setResponseArray(competitionResponse?.result);
            setLoading(false);
        } catch (error) {
            navigate("/pageNotFound", { replace: true })
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllDetails();
    }, []);

    // search input in competitors
    useEffect(() => {
        compTableSearch(responseArray, searchInput, setTableArr)
    }, [responseArray, searchInput]);

    //sort competitor as per column
    const sortCompetitorsHandler = (sorter) => {
        let order = getSortOrder(sorter, sorterData, sortOrder);
        const tableArrCopy = [...tableArr]
        order === 'asc' && sorter === 'id' && tableArrCopy.sort((a, b) => a.card_number - b.card_number);
        order === 'asc' && sorter === 'competitor' && tableArrCopy.sort((a, b) => `${a.dancerData?.lname}`.localeCompare(`${b.dancerData.lname}`));
        order === 'asc' && sorter === 'dob' && tableArrCopy.sort((a, b) => a.dancerData.birthday - b.dancerData.birthday);
        order === 'asc' && sorter === 'competitions' && tableArrCopy.sort((a, b) => a.competitonData[0].code.localeCompare(b.code));

        order === 'desc' && sorter === 'id' && tableArrCopy.sort((a, b) => b.card_number - a.card_number);
        order === 'desc' && sorter === 'competitor' && tableArrCopy.sort((a, b) => `${b.dancerData.lname}`.localeCompare(`${a.dancerData.lname}`));
        order === 'desc' && sorter === 'dob' && tableArrCopy.sort((a, b) => b.dancerData.birthday - a.dancerData.birthday);
        order === 'desc' && sorter === 'competitions' && tableArrCopy.sort((a, b) => b.competitonData[0].code.localeCompare(a.code));

        setTableArr(tableArrCopy);
        setSorterData(sorter);
        setSortOrder(order);
    };

    //print single card handler
    const printCardHandler = async (e, dancer) => {
        const urlApi = `${process.env.REACT_APP_BASE_URL}/registration/printcard/pdf`;
        const payload = {
            registrationId: dancer._id,
            type: 'one',
        };
        postPrintHandler(urlApi, payload, setLoading, setErr)
    };

    //print all cards
    const printAllCardHandler = async (e) => {
        const urlApi = `${process.env.REACT_APP_BASE_URL}/registration/printcard/pdf`;
        const payload = {
            eventId: eventId, type: 'all',
        };
        postPrintHandler(urlApi, payload, setLoading, setErr)
    };

    return (
        <>
            {loading && <Loader />}
            <HeaderOrg />
            <SingleLineTitle titleName="Competitor List" />
            <div className="org-body-white">
                <div className="d-flex gap-2 align-items-center mt-3">
                    <div className="org-body-title">Competitors</div>
                    <div className="competitor-count ">{tableArr.length}</div>
                </div>
                <div className="mb-3 hr-org-body" />
                <div className="search-label" style={{ marginTop: '33px' }}> Search:</div>
                <div className="d-sm-flex align-items-center justify-content-between">
                    <input
                        type="text"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        placeholder="Search..."
                        className="searchFeisComp searchFeisComps"
                    />
                    <Button
                        onClick={(e) => printAllCardHandler(e)}
                        className={responseArray?.length > 0 ? 'text-btn p-0 mt-3 mt-md-0' : 'text-btn-disabled p-0 mt-3 mt-md-0'}
                    >
                        Print All Number cards{' '}
                    </Button>
                </div>
                <div style={{ width: '100%', overflowX: 'scroll', marginTop: '30px', marginBottom: '50px', }}>
                    <table className="table my_table competitorTable ">
                        <tbody>
                            <tr className="header-row">
                                <td className=" mx-5 w-10 p-25-15"> #&nbsp;
                                    <span><img className="cursor-pointer" src={sort} alt="" onClick={() => sortCompetitorsHandler('id')} /></span>
                                </td>
                                <td className="mx-5 w-35 p-25-5">Competitor&nbsp;
                                    <span><img className="cursor-pointer" src={sort} alt="" onClick={() => sortCompetitorsHandler('competitor')} /></span>
                                </td>
                                <td className="w-10 p-25-5">DOB&nbsp;
                                    <span><img className="cursor-pointer" src={sort} alt="" onClick={() => sortCompetitorsHandler('dob')} /></span>
                                </td>
                                <td className="w-25 p-25-5">Competitions&nbsp;
                                    <span><img className="cursor-pointer" src={sort} alt="" onClick={() => sortCompetitorsHandler('competitions')} /></span>
                                </td>
                                <td className="w-20 p-25-5 text-center">Card</td>
                            </tr>
                            {tableArr.map((dancer) => {
                                return (
                                    <tr key={uuidv4()} style={{ background: '#FFFFFF', fontSize: '12px', height: '31px', }}>
                                        <td className="mx-5 py-4 p-25-15 w-10 grey-30">{dancer?.card_number}</td>
                                        <td className="mx-5 w-35 grey-30">
                                            <div> {dancer?.dancerData?.fname}&nbsp; {dancer?.dancerData?.lname}
                                                <div style={{ opacity: '0.3' }}> {dancer?.dancerData?.school}</div>
                                            </div>
                                        </td>
                                        <td className="w-10 grey-30">{dancer?.dancerData?.birthday}</td>
                                        <td className="w-25 grey-30">
                                            {dancer?.competitonData && groupAndSortComp(dancer.competitonData).map((item, index) => {
                                                return dancer?.competitonData?.length === index + 1 ? `${item?.code}` : `${item?.code}, `;
                                            })}
                                        </td>
                                        <td className="w-20 grey-30 text-center" >
                                            <Button onClick={(e) => printCardHandler(e, dancer)} className="text-btn p-0 text-btn-tab">Number card</Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {!loading && tableArr.length === 0 && searchInput !== '' && responseArray.length > 0 && (
                        <div className="d-flex justify-content-center my-5"> There are no competitors matching your search</div>
                    )}
                    {!loading && responseArray.length === 0 && (
                        <div className="d-flex justify-content-center my-5">There are no competitors registered for this event</div>
                    )}
                    {!loading && err && (
                        <div className="d-flex justify-content-center error-class">{err}</div>
                    )}
                </div>
            </div>
            <Help />
        </>
    );
};

export default FeisCompetitors;
