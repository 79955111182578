import { CircularProgress } from "@mui/material";
import * as React from "react";
import "./Loader.css";
const Loader = () => {
  return (
    <div style={{ position: "absolute" }}>
      <div className="page-loader" id="page_loader">
        <CircularProgress color="inherit" />
        <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  );
};

export default Loader;
