import React, { useState, useEffect } from 'react';
import { serviceConsumer } from '../../network/ServiceConsumer';
import Loader from '../../Components/Loader/Loader';
import Card from '../../Components/CardOrg/Card';
import { useNavigate } from 'react-router-dom';
import InfiniteScrollComponent from '../../Components/InfiniteScrollComponent';
import moment from 'moment';

const PreviousEvents = ({ eventType, eventStatus }) => {
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [page, setPage] = useState(1);
    const [apiUrl, setApiUrl] = useState(null); // State to hold the apiUrl
    const navigate = useNavigate();
    const limit = 10;
    const date = moment(new Date()).utc(true).format()
    const organizerId = localStorage.getItem("organizerId") ? localStorage.getItem("organizerId") : ""

    const getEvents = async (url) => {
        const closedPayload = {
            page: page,
            limit: limit,
            type: "CLOSED",
            date: date,
            organizer_id: organizerId
        }
        try {
            const payload = eventStatus == "open" ? { page, limit, date, organizer_id: organizerId } : closedPayload;
            const response = await serviceConsumer("POST", `${process.env.REACT_APP_BASE_URL}${url}`, payload);
            if (response?.Feis?.results?.length > 0) {
                setEvents((prevEvents) => [...prevEvents, ...response?.Feis?.results]);
                setHasMore(response?.Feis?.next?.hasMore);
                setPage(response?.Feis?.next?.hasMore ? response?.Feis?.next?.page : page);
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    const getUrl = () => {
        if (eventStatus === 'open') {
            const url = eventType === 'Feiseanna' ? '/feis/organizer/feis/previousevents' : '/feis/organizer/grade/events'
            return url
        } else {
            const url = eventType === 'Feiseanna' ? '/feis/organizer/feis/closed' : '/feis/organizer/grade/closed'
            return url
        }
    }
    useEffect(() => {
        const newApiUrl = getUrl()
        setLoading(true);
        setApiUrl(newApiUrl);  // Update the state with the latest apiUrl
        getEvents(newApiUrl);
    }, []);

    return (
        <div className="db-body">
            {loading && <Loader />}
            {events.length > 0 &&
                <EventList
                    events={events}
                    title={eventType === 'Feiseanna' ? 'Fiesannas' : 'Grade Exam'}
                    hasMore={hasMore}
                    getEvents={getEvents}
                    apiUrl={apiUrl}
                    eventType={eventType}
                    navigate={navigate}
                />}
            {!loading && events.length === 0 && <div className="py-5 text-center">No events.</div>}
        </div>
    );
};

export default PreviousEvents;

const EventList = ({ events, title, hasMore, getEvents, apiUrl, eventType, navigate }) => (
    <>
        <div className="db-org-title pt-4">{title}</div>
        <div className="mt-1 hr-org-body" />
        <InfiniteScrollComponent events={events} hasMore={hasMore} getAllEvents={() => getEvents(apiUrl)}>
            {events.map((event) => renderEventCard(event, eventType, navigate))}
        </InfiniteScrollComponent>
    </>
);
const renderEventCard = (event, eventType, navigate) => (
    <Card
        logo={event?.logo}
        eventType={event?.eventType}
        eventName={event?.name}
        date={eventType === 'Feiseanna' ? event?.feis_date : event?.exam_date}
        venue={event?.venue_city}
        state={event?.state}
        onClick={() => navigateToEdit(event, eventType, navigate)}
        btnName="Select"
    />
);
const navigateToEdit = (event, eventType, navigate) => {
    localStorage.setItem("ID", event?._id);
    localStorage.removeItem("TAB");
    const route = eventType === 'Feiseanna' ? '/feis/create?previousEvent=true' : '/grade/create?previousEvent=true';
    navigate(route);
};
