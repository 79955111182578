import { SET_MODAL } from "../redux/actionTypes/modalActionType";

//common fn to open select old collaborator from collaboratorlist and addnew collaborator modal
export const openSelectOldCollabModal = (title, eventId, titleComparator, dispatch) => {

    const otherModalName = titleComparator === "Sponsor" ? `SelectOld${title}s` : `SelectOld${title}`;
    const sponsorModalName = titleComparator === "Sponsors" ? `NewSponsors` : "Sponsors";
    console.log(sponsorModalName)
    if (title !== titleComparator) {
        dispatch({
            type: SET_MODAL,
            payload: {
                modal: otherModalName,
                eventId: eventId,
            }
        })
    } else {
        dispatch({
            type: SET_MODAL,
            payload: {
                modal: sponsorModalName,
                eventId: eventId,
            }
        })
    }
}