import React from 'react'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import "./SearchInput.css"

const SearchInput = ({ searchInput, setSearchInput }) => {
    return (
        <div
            className="d-flex align-items-center searchDiv"
        >
            <SearchOutlinedIcon style={{ margin: '0px 5px' }} />

            <input
                type="text"
                name="search"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder= "Search..."
                className="search" 
            />
        </div>
    )
}

export default SearchInput