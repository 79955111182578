import React from 'react'
import moment from 'moment'
import Back from '../Back/Back'
import "./ManageTitle.css"
import favicon from "../../Assets/favicon.ico"
import Button from '../Button/Button'
import { formatDate } from '../../helper/formatDate'
import { useNavigate } from 'react-router-dom'

const ManageTitle = (props) => {
    const { eventLogo, eventName, eventType, eventDate, button, textButton, className, className1, onClick, textBtnHandler, loading, textBtnClass, onBackClick, button1 } = props
    const eventDateConverted = moment(formatDate(eventDate)).format(" MMMM Do, YYYY")

    const navigate = useNavigate()
    const navigateToDashboard = () => {
        eventType === "Feiseanna" ? navigate("/feis") : navigate("/grade")
    }

    const renderTitle = () => {
        return <>
            <div className="my-3"><Back onBackClick={onBackClick} /></div>
            <div className="d-flex align-items-center gap-2 my-2">
                <img className="manage-title-img" src={eventLogo ? eventLogo : favicon} alt="" onClick={navigateToDashboard} />
                <div className="org-title-name">{eventName}</div>
            </div>
            <div className="d-flex align-items-center gap-2 my-2 mb-4">
                <div className="manage-title-type">{eventType === "Feiseanna" ? "Feisanna" : "Grade Exam"}</div>
                <div className="manage-title-date">{eventDateConverted}</div>
            </div>
        </>
    }

    return (
        <>
            {!button && !textButton &&
                <div className="manage-header">
                    <div className={loading ? "invisible" : ""}>{renderTitle()}</div>
                </div>
            }
            {((button && !textButton && !button1) || (!button && textButton && !button1)) &&
                <div className="manage-header">
                    <div className={loading ? "invisible" : "title-with-btn"}>
                        <div >{renderTitle()}</div>
                        <div className="title-btn-div mb-4">
                            {button && !textButton && <Button className={className} onClick={onClick}>{button}</Button>}
                            {!button && textButton && <Button className={className} onClick={textBtnHandler}>{textButton}</Button>}
                        </div>
                    </div>
                </div>
            }

            {((button && textButton) || (button && button1)) && <div className="manage-header">
                <div className={loading ? "invisible" : "title-with-btn"}>
                    <div style={{ width: "100%" }}>
                        <div className="my-3"><Back onBackClick={onBackClick} /></div>
                        <div className="d-flex align-items-center gap-2 my-2">
                            <img className="manage-title-img" src={eventLogo ? eventLogo : favicon} alt="" onClick={navigateToDashboard} />
                            <div className="org-title-name">{eventName}</div>
                        </div>
                        <div className="d-md-flex justify-content-between align-items-start">
                            <div className="d-flex align-items-center gap-2 my-0 ">
                                <div className="manage-title-type">{eventType === "Feiseanna" ? "Feisanna" : "Grade Exam"}</div>
                                <div className="manage-title-date">{eventDateConverted}</div>
                            </div>
                            <div className="title-btn-div d-flex align-items-center justify-content-between mb-1 gap-2">
                                <div>
                                    {textButton && <Button className={textBtnClass ? textBtnClass : "text-btn px-0"} onClick={textBtnHandler}>{textButton}</Button>}
                                    {/* using textBtnHandler as name for 2nd button to reduce prop counts */}
                                    {button1 && <Button className={className1} onClick={textBtnHandler}>{button1}</Button>}
                                </div>
                                <div>
                                    <Button className={className} onClick={onClick}>{button}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            <div className="hr-org-title" />
        </>
    )
}

export default ManageTitle