import React from 'react'
import deleteIcon from "../../../../../Assets/delete.svg";

const DancerLevelData = ({ index, dancer, deleteItemHandler }) => {
  return (
    <div>
      {Object.keys(dancer.levelPriceData)?.map(level => {
        const competitions = dancer.levelPriceData[level];
        if (competitions?.length) {
          return (
            <>
              {level}
              {
                competitions?.map((competition) => {
                  return (
                    <div
                      className="d-flex px-2 justify-content-between align-items-center"
                      style={{ marginTop: "10px" }}
                      key={competition._id}
                    >
                      <div className="d-flex">
                        <img
                          style={{ cursor: "pointer" }}
                          src={deleteIcon}
                          onClick={() => deleteItemHandler(competition._id, dancer._id || dancer.dancer_id, index, level)}
                          alt=""
                        />
                        <div className=" mx-5 reg-in-det">
                          ${competition?.code} - {competition?.description} - {competition?.price}
                        </div>
                      </div>
                      <div className=" reg-in-det">${competition?.price}</div>
                    </div>
                  );
                })
              }
            </>
          )
        }
      })}
    </div>
  )
}

export default React.memo(DancerLevelData)