import { SET_MODAL } from "../actionTypes/modalActionType";

const INITIAL_STATE = {
    modal: "",
    eventId: ""
};

export const modalReducer = (state = INITIAL_STATE, action) => {

    if (action.type === SET_MODAL) {
        return { ...state, ...action.payload };
    }
    return state;
}