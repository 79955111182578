import React from 'react'

import allAwarded from "../../Assets/AllAwarded.svg"
import awardsPending from "../../Assets/AwardsPending.svg"
import resultsPending from "../../Assets/resultsUnpublished.svg"

import AwardTableFilters from './AwardTableFilters'
import Checkbox from "../../Components/Checkbox/Checkbox"
import { serviceConsumer } from '../../network/ServiceConsumer'
import { findSuffixes } from '../../helper/findSuffixes'
import { CustomTooltip } from '../../Components/Tooltip/CustomToolTip'


const AwardsTable = ({ filter, setFilter, competitorList, getCompetitorList }) => {
    const awardStatusLogos = {
        award_collected: allAwarded,
        award_not_collected: awardsPending,
        results_not_published: resultsPending
    }
    const awardStatusTooltips = {
        award_collected: "All results published and picked up",
        award_not_collected: "All results published but not all picked up",
        results_not_published: "All results not published"
    }

    const updatedAward = async (competitor, competition) => {
        const url = `${process.env.REACT_APP_BASE_URL}/award/update`
        const payload = {
            dancerId: competitor?.dancerData?._id,
            competitionId: competition?.competitionDetails?._id,
        }
        try {
            await serviceConsumer("POST", url, payload);
            getCompetitorList();
        } catch (err) {

        }

    }
    const getTitle = (competition) => {
        return `${competition?.competitionDetails?.code} - ${competition?.competitionDetails?.description} (${competition?.competitionDetails?.competitorCount})`
    }

    return (
        <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px", marginBottom: "50px" }}>
            <table className="table my_table competitorTable ">
                <tbody>
                    <tr className="header-row">
                        <td className=" mx-5 w-10 p-25-5">Status&nbsp;
                            <AwardTableFilters filter={filter} setFilter={setFilter} />
                        </td>
                        <td className="mx-5 w-5 p-25-5">#</td>
                        <td className="w-25 p-25-5">Competitor</td>
                        <td className="w-60 p-25-5">Competitions</td>
                    </tr>

                    {competitorList?.map((competitor) =>

                        <tr style={{ fontSize: "12px", height: "61px" }} key={competitor?.cardNumber}>
                            <td className=" mx-5 w-10 grey-30 p-25-5" >
                                <CustomTooltip title={awardStatusTooltips[competitor?.overallStatus]} arrow>
                                    <img src={awardStatusLogos[competitor?.overallStatus]} alt="" />
                                </CustomTooltip>
                            </td>
                            <td className="mx-5 w-5 grey-30" >{competitor?.cardNumber}</td>
                            <td className="w-25 grey-30" >
                                <div>{competitor?.dancerData?.fname}&nbsp;{competitor?.dancerData?.lname}</div>
                                <div style={{ opacity: '0.3' }}>{competitor?.dancerData?.school} </div>
                            </td>
                            <td className="w-60 grey-30">
                                {/* <div className="competitionsInnerScroll d-flex gap-2" > */}
                                <div className='d-flex flex-wrap gapStyle' >
                                    {competitor?.competitionList?.map((competition) =>
                                        <div className="d-flex " key={competition?.competitionDetails?.code}>
                                            {(competition?.isResultPublished && competition?.overallPosition === 0) &&
                                                <div className="d-flex ">
                                                    <CustomTooltip title={getTitle(competition)} arrow>
                                                        <div style={{ cursor: "pointer" }}>{`${competition?.competitionDetails?.code}`}</div>
                                                    </CustomTooltip>
                                                    <div className="font-weight-bold">{` : DNP`}</div>
                                                </div>
                                            }
                                            {(!competition?.isResultPublished && competition?.overallPosition === 0) &&
                                                <div className="d-flex ">
                                                    <CustomTooltip title={getTitle(competition)} arrow>
                                                        <div style={{ opacity: "0.3" }}>{`${competition?.competitionDetails?.code} :`}</div>
                                                    </CustomTooltip>
                                                </div>
                                            }
                                            {(competition?.isResultPublished && competition?.overallPosition !== 0) &&
                                                <div className="d-flex ">
                                                    <CustomTooltip title={getTitle(competition)} arrow>
                                                        <div style={{ cursor: "pointer" }}>{`${competition?.competitionDetails?.code} : ${findSuffixes(competition?.overallPosition)}`}</div>
                                                    </CustomTooltip>
                                                    <div> <Checkbox onChange={() => updatedAward(competitor, competition)} checked={competition?.isAwarded} classCheck tooltip={true} /></div>
                                                </div>
                                            }

                                        </div>
                                    )}

                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default AwardsTable
