import React from 'react'
import Modal from "@mui/material/Modal";
import { useNavigate } from 'react-router-dom';

const GoLiveConfirmationModal = ({ openModal, setOpenModal, from, type }) => {
    const navigate = useNavigate()

    const modalCloseHandler = () => {
        setOpenModal(false);
        const url = type === "Feisanna" ? "/feis" : "/grade"
        from === "goLive" && navigate(url);
    }

    const getContentOfModal = () => {
        if (from === "goLive") return "Your request for the event to go live has been successfully sent.You will be notified once it is approved."
        else if (from === "dashboard") return "Please wait until admin approves the event"
        else if (from === "printAll") return <div>Score Sheet is being processed.<br />You will receive an email notification with the zip file.</div>
    }
    return (
        <Modal sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }} open={openModal}>
            <div className={from === ("goLive" || "printAll") ? "org-modal-div GoLiveModalWidth p-5" : "org-modal-div py-4 py-md-5"} >
                <div>
                    <p className="modal-org-subtitle text-center">
                        {getContentOfModal()}
                    </p>
                    <div className="d-flex justify-content-center gap-3 mt-4">
                        <div><button className="filled" onClick={modalCloseHandler}>Okay</button></div>
                    </div>
                </div>
            </div>
        </Modal >
    )
}

export default GoLiveConfirmationModal