import React, { useState } from 'react'
import edit from "../../Assets/edit-comp-action.svg"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ConfirmDeleteModal from '../../Components/Modals/OrganizerModel/ManageFeis/Tabulate/ConfirmDeleteModal';
import { serviceConsumer } from '../../network/ServiceConsumer';
import Loader from '../../Components/Loader/Loader';
import { ShowToastMessage } from '../../helper/ShowToastMessage';
import { Avatar } from "@mui/material";
import AddCollabModal from '../../Components/Modals/OrganizerModel/ManageCollab/AddCollabModal';

const CollaboratorTable = ({ list, page, getCollabs, }) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [editCollab, setEditCollab] = useState("")
    const [deleteCollab, setDeleteCollab] = useState("")

    const serializeHelper = (page - 1) * 10

    const editClickHandler = (collab) => {
        setEditCollab(collab)
        setOpenEditModal(true)
    }

    const deleteClickHandler = (collab) => {
        setDeleteCollab(collab)
        setOpenDeleteModal(true)
    }

    const confirmDeleteHandler = async () => {
        try {
            setLoading(true)
            const url = `${process.env.REACT_APP_BASE_URL}/people/${deleteCollab?._id}`;
             await serviceConsumer("DELETE", url)
            ShowToastMessage("success", "Collaborator deleted successfully")
            setOpenDeleteModal(false)
            getCollabs()
            setLoading(false)
        } catch (err) {
            setLoading(false)
            ShowToastMessage("error", "Something went wrong")
        }
    }

    const cancelDeleteHandler = () => {
        setOpenDeleteModal(false)
    }

    const closeEditModal = () => {
        setOpenEditModal(false)
    }

    return (
        <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px", marginBottom: "50px" }}>
            {loading && <Loader />}
            <table className="table my_table competitorTable ">
                <tbody>
                    <tr className="header-row">
                        <td className="mx-5 w-3 p-25 text-center">S.No</td>
                        <td className="mx-5 w-8 p-25-5">Profile</td>
                        <td className="mx-5 w-8 p-25 ">Name</td>
                        <td className="mx-5 w-8 p-25 ">Region</td>
                        <td className="mx-5 w-8 p-25 ">Type</td>
                        <td className="mx-5 w-10 text-center p-25-5">Actions</td>
                    </tr>
                    {list?.map((collab, index) => {
                        return <tr key={collab?._id} style={{ fontSize: "12px", height: "61px" }}>
                            <td className="mx-5 w-3 p-25 text-center">{serializeHelper + (index + 1)}</td>
                            <td className="mx-5 w-8 p-25-5">
                                <Avatar style={{ width: "50px", height: "50px" }} src={collab?.photo} alt="profile" />
                            </td>
                            <td className="mx-5 w-8 p-25 ">{collab?.name}</td>
                            <td className="mx-5 w-8 p-25 ">{collab?.location}</td>
                            <td className="mx-5 w-8 p-25 ">{collab?.type}</td>

                            <td className="w-10 text-center ">
                                <div className="d-flex justify-content-center gap-3">
                                    <img onClick={() => editClickHandler(collab)} style={{ cursor: "pointer" }} src={edit} alt="edit" />
                                    <DeleteOutlineOutlinedIcon
                                        style={{ color: "red", marginLeft: "10px", cursor: "pointer" }}
                                        onClick={() => deleteClickHandler(collab)}
                                    />
                                </div>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            {openDeleteModal && <ConfirmDeleteModal modalState={openDeleteModal} confirm={confirmDeleteHandler} cancel={cancelDeleteHandler} dancerPage={true} />}
            {openEditModal && <AddCollabModal addOrEdit="Edit" onClose={closeEditModal} editCollab={editCollab} getList={getCollabs} />}
        </div>
    )
}

export default CollaboratorTable
