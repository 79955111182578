import axios from "axios";
import { serviceConsumer } from "../network/ServiceConsumer";
import { sortCompetitions } from "./SortSyllabus";
import { ShowToastMessage } from "./ShowToastMessage"

export const downloadPdf = async (url, setLoading, event) => {

  setLoading(true);
  try {
    const response = await axios.get(url, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + window.btoa(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`),
        accesstoken: localStorage.getItem("accessToken"),
      },
    })
    const blob = new Blob([response?.data], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", `${event?.name} .pdf`);
    document.body.appendChild(link);
    link.click();
    setLoading(false);
  } catch (err) {
    setLoading(false)
    ShowToastMessage("error", "Something went wrong!")
  }

}

export const downloadCSV = async (ManageType, setLoading, eventId, eventName) => {
  let arr = [];
  try {
    setLoading(true);
    const url =
      ManageType == 'feis'
        // ? `${process.env.REACT_APP_BASE_URL}/feis/${eventId}/competition`
        ? `${process.env.REACT_APP_BASE_URL}/feis/syllabus/${eventId}`
        : `${process.env.REACT_APP_BASE_URL}/grade-exam/fees/${eventId}`;
    const response = await serviceConsumer('GET', url);
    if (ManageType == 'feis') {
      response.result.forEach(levelObj => {
        const sortedCompetitions = sortCompetitions(levelObj?.competitions)
        arr = [...arr, ...sortedCompetitions]
      });
    }
    const tableArr =
      ManageType == 'feis'
        ? arr?.map((competition, index) => [index + 1, competition.group, competition.code, competition.dancers.length])
        : response?.grade_exam_fees?.map((competition, index) => [index + 1, competition.grade_name,]);
    const header = ManageType == 'feis'
      ? [['serial No', 'Level', 'Competition Code', 'Competitors']]
      : [['serial No', 'Grade']];
    const tableArrWithHeaders = header.concat(tableArr);
    downloadCsv(tableArrWithHeaders, eventName);
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
}
const downloadCsv = (tableData, eventName) => {
  // Convert tableData to CSV format
  const csvContent = 'data:text/csv;charset=utf-8,' + tableData?.map((row) => Object.values(row).join(',')).join('\n');
  // Create a download link
  const link = document.createElement('a');
  link.href = encodeURI(csvContent);
  link.download = `${eventName}-Competitions`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const jsonToCSV = (dancers) => {
  const csvRows = [];

  let maxGrades = 0
  dancers.forEach(dancer => {
    const gradesRegistered = dancer.gradeExamFeesData.map(data => data.grade_name)
    maxGrades = maxGrades < gradesRegistered?.length ? gradesRegistered.length : maxGrades
    const row = [dancer.dancerData.fname, dancer.dancerData.lname, dancer.dancerData.birthday, dancer.dancerData.school, [...gradesRegistered]];
    csvRows.push(row.join(','));
  });

  const headers = ['First Name', 'Last Name', 'DOB', 'School',];
  for (let i = 0; i < maxGrades; i++) headers.push("Grade")
  csvRows.unshift(headers.join(','));

  return csvRows.join('\n');
};

export const downloadCandidatesCSV = (csvContent, eventName) => {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) { // feature detection
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `Candidates-${eventName}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};