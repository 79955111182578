import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import AccomodationModel from '../../Components/Modals/OrganizerModel/AccomodationModel';
import Loader from '../../Components/Loader/Loader';
import MainDiv from '../../Components/AddedAdjdiv/MainDiv';
import AccomodationDiv from '../../Components/AddedAdjdiv/AccomodationDiv';
import AddButton from '../../Components/AddButton/AddButton';
import { accessTillSyllabus } from '../../helper/CreateDispatches';
import { serviceConsumer } from '../../network/ServiceConsumer';
import SelectCollaborators from '../../Components/Modals/OrganizerModel/CreateFlowCollaborator/SelectCollaborators';
import NewCollaboratorModal from '../../Components/Modals/OrganizerModel/CreateFlowCollaborator/NewCollaboratorModal';
import { getAccomodationData } from '../../network/CreateEventFeis';
import EditCollaboratorModal from '../../Components/Modals/OrganizerModel/CreateFlowCollaborator/EditCollaboratorModal';

const CollaboratorFies = () => {
  const [openCollabModal, setOpenCollabModal] = useState("")
  const [openNewCollabModal, setOpenNewCollabModal] = useState("")
  const [openModalAccomodation, setopenModelAccomodation] = useState(false);
  const [adjucatorIds, setadjucatorIds] = useState([]);
  const [sponserIds, setSponsersIds] = useState([]);
  const [musicianId, setMusicianId] = useState([]);
  const [adj, setAdj] = useState([]);
  const [mus, setmus] = useState([]);
  const [spn, setspn] = useState([]);
  const [loading, setLoading] = useState();
  const [acccmodataionVal, setaccomodationVal] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [editCollab, setEditCollab] = useState(null)
  let EventID = localStorage.getItem('ID');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showAdjucator = false

  useEffect(() => {
    accessTillSyllabus(dispatch)
    setInitialRender(false);
    setLoading(true);
    Promise.all([getCollabs("adjudicators", setAdj, setadjucatorIds),
    getCollabs("musicians", setmus, setMusicianId),
    getCollabs("sponsors", setspn, setSponsersIds),
    getAccomodationData(setLoading, "feis", EventID, setaccomodationVal, initialRender)])
      .then(res => setLoading(false))
      .catch(err => setLoading(false))
  }, [])

  const handleCollabModals = (collabType) => {
    collabType === "sponsor" && setOpenNewCollabModal(collabType)
    setOpenCollabModal(collabType);
  }
  const handleOpenModelAccomodation = () => {
    setopenModelAccomodation(!openModalAccomodation);
  };

  const getCollabs = async (collab, setCollab, setCollabIds) => {
    try {
      !initialRender && setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}/people/${EventID}/${collab}`;
      const response = await serviceConsumer('GET', url);
      setCollab(response.people);
      setCollabIds(response.people.map((men) => men._id));
      !initialRender && setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  const getCollabForDelete = (from) => {
    if (from === 'adjucators') return getCollabs("adjudicators", setAdj, setadjucatorIds)
    else if (from === 'musician') return getCollabs("musicians", setmus, setMusicianId)
    else return getCollabs("sponsors", setspn, setSponsersIds);
  }
  const onDelete = async (id, from) => {
    try {
      if (from === 'adjucators' || from === 'musician' || from === 'sponser') {
        const url = `${process.env.REACT_APP_BASE_URL}/people/${id}/delete/${EventID}`;
        await serviceConsumer('DELETE', url);
        getCollabForDelete(from)
      }
      else if (from === 'acccmodataion') {
        setLoading(true);
        if (EventID) {
          const url = `${process.env.REACT_APP_BASE_URL}/feis/${EventID}/del/accomdations/${id}`;
          await serviceConsumer('DELETE', url);
          getAccomodationData(setLoading, "feis", EventID, setaccomodationVal, initialRender)
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }
  const onEdit = (collabDetail, from) => {
    setEditCollab({ ...collabDetail, from: from })
  }
  // submitHandler
  const finishHandler = async () => {
    let val = {
      adjudicators: adjucatorIds.filter(item => item != null),
      sponsors: sponserIds.filter(item => item != null),
      musicians: musicianId.filter(item => item != null),
      page: 7,
    };
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}/feis/${EventID}`;
      const response = await serviceConsumer('PUT', url, val);
      if (response.success === true) {
        localStorage.setItem('page', 7);
        localStorage.removeItem('previousEvent');
        navigate('/feis/create/finalFeisDetails');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const collaboratorInitialValues = {};

  const getCollabHandler = () => {
    if (openNewCollabModal === "adjudicator") {
      return getCollabs("adjudicators", setAdj, setadjucatorIds)
    } else if (openNewCollabModal === "musician") {
      return getCollabs("musicians", setmus, setMusicianId)
    } else {
      return getCollabs("sponsors", setspn, setSponsersIds)
    }
  }
  return (
    <div className="AboutOrg">
      <Container className="px-0">
        {loading && <Loader />}
        <Formik initialValues={collaboratorInitialValues}>
          {() => (
            <Form className="form form-label-right" autoComplete="off">
              <div className="syllabusMain">
                <div className="">
                  <div className="detailsHeaders">
                    <h4 className="eventPage">Complete collaborators</h4>
                    <p className="eventDescription"> Adjudicators, Sponsors, And others.</p>
                  </div>
                  <label className="eventGradeColabLabel">Adjudicators:</label>
                  <br />
                  {adj?.length > 0 ? renderPeopleData(adj, 'adjucators', onDelete, onEdit) : <div>No adjudicators</div>}
                  <AddButton className="plusIconbtn" onClick={() => handleCollabModals("adjudicator")}>Add</AddButton>
                  <br />
                  <label className="eventGradeColabLabel ">Musicians:</label>
                  <br />
                  {mus?.length > 0 ? renderPeopleData(mus, "musician", onDelete, onEdit) : <div>No musicians</div>}
                  <AddButton className="plusIconbtn" onClick={() => handleCollabModals("musician")}>Add</AddButton>
                  <br />
                  <label className="eventGradeColabLabel mt-3">Accomodations:</label>
                  <br />
                  {acccmodataionVal?.length > 0 ? acccmodataionVal?.map((accomodation, index) =>
                    <AccomodationDiv
                      key={accomodation._id}
                      name={accomodation?.name}
                      photo={accomodation?.photo}
                      country={accomodation?.country}
                      from={'acccmodataion'}
                      onDelete={onDelete}
                      id={accomodation._id}
                    />
                  ) : <div>No accommodations</div>}
                  {acccmodataionVal?.length < 5 && (<AddButton className="plusIconbtn" onClick={handleOpenModelAccomodation}>Add</AddButton>)}
                  <br />
                  <label className="eventGradeColabLabel mt-3"> Sponsors:</label>
                  <br />
                  {spn?.length > 0 ? renderPeopleData(spn, "sponser", onDelete, onEdit) : <div>No sponsors</div>}
                  <AddButton className="plusIconbtn" onClick={() => handleCollabModals("sponsor")}>Add</AddButton>
                  <div className="mt-3 mb-5">
                    <button onClick={finishHandler} className="filled"> Finish </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Container>

      {openCollabModal &&
        <SelectCollaborators
          setOpenCollabModal={setOpenCollabModal}
          openCollabModal={openCollabModal}
          setOpenNewCollabModal={setOpenNewCollabModal}
          getCollab={openCollabModal === "adjudicator"
            ? () => getCollabs("adjudicators", setAdj, setadjucatorIds)
            : () => getCollabs("musicians", setmus, setMusicianId)} />}
      {openNewCollabModal &&
        <NewCollaboratorModal
          from="feis"
          setOpenCollabModal={setOpenCollabModal}
          setOpenNewCollabModal={setOpenNewCollabModal}
          openNewCollabModal={openNewCollabModal}
          getCollab={getCollabHandler} />}

      {openModalAccomodation && (
        <AccomodationModel
          modalState={openModalAccomodation}
          action={handleOpenModelAccomodation}
          showAdjucator={showAdjucator}
          accomodationData={() => getAccomodationData(setLoading, "feis", EventID, setaccomodationVal, initialRender)}
        />
      )}
      {editCollab && <EditCollaboratorModal editCollab={editCollab} setEditCollab={setEditCollab} getCollabOnEdit={getCollabForDelete} type="feis" />}
    </div>
  );
};

export default CollaboratorFies;
const renderPeopleData = (people, from, onDelete, onEdit) => {
  return people?.map((item) =>
    <MainDiv
      key={item?._id}
      name={item?.name}
      location={item?.location}
      photo={item?.photo}
      from={from}
      id={item._id}
      onDelete={onDelete}
      onEdit={onEdit}
      item={item}
    />
  )
}
