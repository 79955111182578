import { serviceConsumer } from "../network/ServiceConsumer";

export const handleLogoUpload = (event, setLogo, setblogo, setLoading, setImgErr, setEdit) => {
    const selectedLogo = event.target.files[0];

    // Process the selected file or perform any operations here
    if (
        selectedLogo.type === 'image/png' ||
        selectedLogo.type === 'image/svg' ||
        selectedLogo.type === 'image/jpg' ||
        selectedLogo.type === 'image/jpeg'
    ) {
        setImgErr('');
        if (selectedLogo) {
            handleLogoSelected(event, setLogo, setblogo, setLoading, setEdit, selectedLogo)
        }
    } else {
        setImgErr('Please upload a file with the extension .jpg, .png, jpeg or .svg.');
    }
}

const handleLogoSelected = (event, setLogo, setblogo, setLoading, setEdit, selectedLogo) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        const img = new Image();
        img.onload = async () => {
            try {
                setLoading(true);
                let formData = new FormData();
                formData.append('image', event.target.files[0]);
                const url = `${process.env.REACT_APP_BASE_URL}/feis/upload`;
                const response = await serviceConsumer('POST', url, formData);
                setEdit(true)
                if (response?.url) {
                    setLogo(response.name);
                    setblogo(response.url);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        img.src = e.target.result;
    };
    reader.readAsDataURL(selectedLogo);
}

export const handlePdfUpload = async (event, setsyllabus, setsylErr, setLoading, setEdit, schedule, addScheduleToEventDetail) => {
    const selectedSyl = event.target.files[0];
    // Process the selected file or perform any operations here
    if (selectedSyl.type === 'application/pdf') {
        setsylErr('');
        try {
            setLoading(true);
            let formData = new FormData();
            formData.append('image', selectedSyl);
            const url = `${process.env.REACT_APP_BASE_URL}/feis/upload`;
            const response = await serviceConsumer('POST', url, formData);
            !schedule && setEdit(true)
            if (response?.url) {
                setsyllabus(response.name);
                if (schedule) {
                    //it is used as setEdit in About and setShowSuccessToast in manage 
                    addScheduleToEventDetail(response.url, response.name)
                } else {
                    addScheduleToEventDetail(response.url)
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    } else {
        setsylErr('Please upload a file in Pdf Format');
    }
}

export const handlePhotoUpload = async (event, setLoading, setLogo, setUpl, setImgErr, form, acc) => {
    const selectedLogo = event.target.files[0];
    // Process the selected file or perform any operations here
    if (
        selectedLogo.type === 'image/png' ||
        selectedLogo.type === 'image/svg' ||
        selectedLogo.type === 'image/jpg' ||
        selectedLogo.type === 'image/jpeg'
    ) {
        setImgErr('');

        if (selectedLogo) {
            const reader = new FileReader();
            handleFormData(reader, setLoading, setUpl, setImgErr, event, setLogo, form, acc)
            reader.readAsDataURL(selectedLogo);
        }
    } else {
        setImgErr('Please upload a file with the extension .jpg, .png, jpeg or .svg.');
        setUpl("")
    }

}

const handleFormData = (reader, setLoading, setUpl, setImgErr, event, setLogo, form, acc) => {
    reader.onload = (e) => {
        const img = new Image();
        img.onload = async () => {
            if (acc) {
                if (img.width === 1024 && img.height === 683) {
                    upload(setLoading, setUpl, event, setLogo, form)
                } else {
                    setImgErr('The uploaded photo should have dimensions of 1024x683 pixels.');
                    setUpl("")
                }
            }
            else {
                if (img.width === 1024 && img.height === 1024) {
                    upload(setLoading, setUpl, event, setLogo, form)
                } else {
                    setImgErr('The uploaded photo should have dimensions of 1024x1024 pixels.');
                    setUpl("")
                }
            }
        };
        img.src = e.target.result;
    };
}

const upload = async (setLoading, setUpl, event, setLogo, form) => {
    try {
        setLoading(true);
        let formData = new FormData();
        formData.append('image', event.target.files[0]);
        const url = `${process.env.REACT_APP_BASE_URL}/feis/upload`;
        const response = await serviceConsumer('POST', url, formData);
        if (response.url) {
            setLogo(response.url);
            if (response.url) {
                setUpl('Uploaded successfully');
            }
        }
        if (form) form?.setFieldValue('photo', event.target.files[0]?.name);
        setLoading(false);
    } catch (error) {
        setLoading(false);
    }
}