import React from 'react'
import BorderColorIcon from '@mui/icons-material/BorderColor';

const VenueDetailsSection = ({ eventDetails, onEditClick }) => {
    return (
        <div className="col-lg-6 mainDivLive" id="sndDiv">
            <span className="eventDetailsBlock">Venue Details</span>
            <BorderColorIcon className="editIcon" onClick={onEditClick} />
            <div className="eventsHr"></div>

            <div className="detailsHeading">Venue Name:</div>
            <span className="LiveData">{eventDetails?.venue_name}</span>

            <div className="detailsHeading">Address:</div>
            <div className="LiveData">{eventDetails?.venue}</div>
            <div>{eventDetails?.venue_city}</div>
            <div>{eventDetails?.state}</div>
            <div>{eventDetails?.country}</div>
            <div>  {eventDetails?.postal_code} </div>
        </div>
    )
}

export default VenueDetailsSection