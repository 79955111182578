import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SingleLineTitle from '../../Components/TitleElements/SingleLineTitle'
import HeaderOrg from '../Headers/HeaderOrg'
import AwardsTable from './AwardsTable'
import Loader from '../../Components/Loader/Loader'
import PaginationNew from '../../Components/Pagination/PaginationNew'
import Help from "../../Components/Help/Help"

import { serviceConsumer } from '../../network/ServiceConsumer'

const AwardsTableComponent = () => {
    const [loading, setLaoding] = useState(false)
    const [searchInput, setSearchInput] = useState("")
    const [filter, setFilter] = useState("all")

    // pagination
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(0);
    const [competitorList, setCompetitorList] = useState()

    const navigate = useNavigate()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const eventId = searchParams.get('id');

    useEffect(() => {
        setPage(1)
        const timerId = setTimeout(getCompetitorList, 300)
        return () => clearTimeout(timerId)
    }, [searchInput, filter])

    useEffect(() => {
        getCompetitorList()
    }, [page])

    const getCompetitorList = async () => {
        setLaoding(true)
        const url = `${process.env.REACT_APP_BASE_URL}/award/list/${eventId}`;
        const payload = {
            page: page,
            limit: 10,
            searchKey: searchInput,
            filterStatus: filter//all , award_collected, award_not_collected, results_not_published
        }
        try {
            const response = await serviceConsumer("POST", url, payload)
            setCompetitorList(response?.date?.list)
            setPaginationDetails(response?.date?.totalCount)
        } catch (err) {

        }
        setLaoding(false)
    }

    const setPaginationDetails = (totalData) => {
        let totalPage = Math.ceil(Number(totalData / 10));
        setTotalPages(totalPage);
        let startindex = page % 10 === 0 ? page - 9 : Math.floor(page / 10) * 10 + 1;
        setStartIndex(startindex);
        startindex + 9 > totalPage ? setEndIndex(totalPage) : setEndIndex(startindex + 9);
    }

    const goBackToManagePage = () => {
        navigate(`/feis/manage?id=${eventId}&Manage=feis`)
    }

    return (
        <>
            {loading && <Loader />}
            <HeaderOrg />
            <SingleLineTitle titleName="Awards Table" onBackClick={goBackToManagePage} />
            <div className="org-body-white">
                <div className="search-label" style={{ marginTop: '33px' }}> Search:</div>
                <input
                    type="text"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    placeholder="First name, last name, school and Competition"
                    className="searchFeisComp searchFeisComps"
                />

                <AwardsTable
                    filter={filter}
                    setFilter={setFilter}
                    competitorList={competitorList}
                    getCompetitorList={getCompetitorList} />

                <PaginationNew
                    loading={loading}
                    startIndex={startIndex}
                    endIndex={endIndex}
                    totalPages={totalPages}
                    page={page}
                    setPage={(val) => setPage(val)}
                    setStartIndex={(val) => setStartIndex(val)}
                    setEndIndex={(val) => setEndIndex(val)}
                    dataPerPage={10}
                />

                {!loading && competitorList?.length === 0 && ((searchInput !== "" || filter !== "all")
                    ? < div className='d-flex justify-content-center'> No competitors matches your search</div>
                    : < div className='d-flex justify-content-center'> No competitors </div>)}
                <Help />
            </div>

        </>
    )
}

export default AwardsTableComponent
