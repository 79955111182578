import React, { useState, useRef } from 'react'
import Modal from "@mui/material/Modal";
import closeIcon from "../../../../../Assets/close-icon.svg"
import { useDispatch, useSelector } from 'react-redux';
import { SET_MODAL } from '../../../../../redux/actionTypes/modalActionType';
import { MdContentCopy } from 'react-icons/md'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const modalCloseHandler = (dispatch) => {
    dispatch({
        type: SET_MODAL,
        payload: {
            modal: "",
            eventProp: "",
        }
    })
}

const DirectUrlModal = ({ modalState, manageType }) => {
    const modal = useSelector((state) => state.modalReducer);
    const [copied, setCopied] = useState(false)
    const inputRef = useRef(null);
    const dispatch = useDispatch();

    const handleCopy = () => {
        const textToCopy = inputRef.current.value;
        navigator.clipboard.writeText(textToCopy)
            .then(() => { setCopied(true) })
            .catch((error) => { });
    };

    return (
        <Modal sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }} open={modalState}>
            <div className="org-modal-url d-flex gap-2" style={{ width: "auto", padding: "20px", }}>
                <div>
                    <div className="d-flex" class="copyUrlDiv">
                        <input
                            className="urlInput"
                            type="text"
                            value={manageType === "feis" ? `${process.env.REACT_APP_USER_BASE}/register-dancers?id=${modal.eventId}&type=redirect` : `${process.env.REACT_APP_USER_BASE}/register-grade-exam?id=${modal.eventId}&type=redirect`}
                            ref={inputRef}
                            disabled
                        />
                        <div onClick={handleCopy} style={{ cursor: "pointer" }}>
                            <MdContentCopy style={{ color: copied ? "green" : "black" }} />
                        </div>
                    </div>
                    {copied && <div style={{ color: "green", fontSize: "14px" }}>Copied!</div>}
                </div>
                <div>
                    <img
                        src={closeIcon}
                        alt="close-icon"
                        onClick={() => modalCloseHandler(dispatch)}
                        style={{ marginLeft: "10px", cursor: "pointer", marginTop: "15px" }}
                    />
                </div>
            </div>
        </Modal >
    )
}

export default DirectUrlModal

export const ScheduledPdf = ({ modalState, name, deleteFn }) => {
    const dispatch = useDispatch();
    return (
        <Modal sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }} open={modalState}>
            <div className="org-modal-div bg-white d-flex flex-row align-items-center gap-2 p-3 border border-dark">
                {/* <div className="fileIcon d-flex justify-content-center">{<AttachFileIcon />}</div> */}
                <div style={{ overflowX: "scroll", whiteSpace: "nowrap" }} className=" flex-grow-1 py-3 ps-3">{name}</div>
                <div className="pdfDlticon d-flex justify-content-center flex-end">
                    <DeleteOutlineIcon onClick={() => deleteFn("", "", true)} />
                </div>
                <img
                    className="pe-3"
                    src={closeIcon}
                    alt="close-icon"
                    onClick={() => modalCloseHandler(dispatch)}
                    style={{ cursor: "pointer" }}
                />
            </div>
        </Modal >
    )
}