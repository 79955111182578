import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../Components/SearchInput";
import ParentTable from "./ParentTable";
import OrganizerTable from "./OrganizerTable";
import { serviceConsumer } from "../../network/ServiceConsumer";
import Loader from "../../Components/Loader/Loader";
import PaginationNew from "../../Components/Pagination/PaginationNew";
import { usePrevious } from "../../CustomHooks/UsePrevious";
import SingleLineTitle from "../../Components/TitleElements/SingleLineTitle";

const ManageUsers = () => {
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState("Parent")
  const [orgData, setOrgData] = useState()
  const [parentData, setParentData] = useState()
  const [searchInput, setSearchInput] = useState("")
  const levels = ["Parent", "Organizer"];

  //data for pagination
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);

  const prevUser = usePrevious(user);
  const navigate = useNavigate()
  const serializeHelper = (page - 1) * 10//this helps in calculating serial numbers in table

  const parentWithData = user === "Parent" && parentData?.list?.length > 0
  const orgWithData = user === "Organizer" && orgData?.list?.length > 0


  useEffect(() => {
    const Id = setTimeout(() => {
      // change page back to 1 when you search something or change the user type
      if (prevUser !== user) setPage(1)
      getUsers();
    }, 300);
    return () => clearTimeout(Id);
  }, [user, searchInput, page])

  useEffect(() => {
    if (searchInput === "") setPage(1)
  }, [searchInput])

  const getUsers = async () => {
    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/list-users`;
    const payload = {
      useType: user === "Organizer" ? "organizer" : "user",
      page: page,
      limit: 10,
      searchKey: searchInput
    }
    const response = await serviceConsumer("POST", url, payload)
    setPaginationDetail(response.date)
    user === "Organizer" ? setOrgData(response.date) : setParentData(response.date)
    setLoading(false)
  }

  const setPaginationDetail = (data) => {
    let totalData = data?.resultCount;
    let totalPage = Math.ceil(Number(totalData / 10));
    setTotalPages(totalPage);
    let startindex = page % 10 === 0 ? page - 9 : Math.floor(page / 10) * 10 + 1;
    setStartIndex(startindex);
    startindex + 9 > totalPage ? setEndIndex(totalPage) : setEndIndex(startindex + 9);
  }

  const backToDashboardHandler = () => {
    localStorage.setItem("eventsTab", false)
    navigate("/")
  }

  return (
    <>
      <SingleLineTitle titleName="Users" onBackClick={backToDashboardHandler} />
      <div className="db-body">
        {loading && <Loader />}
        <div className="d-flex flex-column flex-md-row justify-content-between pt-3">
          <div className=" col-sm-8 col-md-4 my-2">
            <SearchInput searchInput={searchInput} setSearchInput={setSearchInput} />
          </div>
          <div className="col-sm-8 col-md-2 my-2">
            <select name="typeOfUser" className="dashboard-select" selected={user} onChange={(e) => setUser(e.target.value)}>
              {levels?.map((levelOption) => (
                <option key={levelOption} value={levelOption}>{levelOption}</option>
              ))}
            </select>
          </div>
        </div>

        {parentWithData && <ParentTable userData={parentData} getUsers={getUsers} serializeHelper={serializeHelper} />}
        {orgWithData > 0 && user === "Organizer" && <OrganizerTable userData={orgData} serializeHelper={serializeHelper} />}
        {!loading && parentData?.list?.length === 0 && orgData?.list?.length === 0 && <div className="d-flex justify-content-center">No data matches your search</div>}

        {(parentWithData || orgWithData) && <PaginationNew
          loading={loading}
          startIndex={startIndex}
          endIndex={endIndex}
          totalPages={totalPages}
          page={page}
          setPage={(val) => setPage(val)}
          setStartIndex={(val) => setStartIndex(val)}
          setEndIndex={(val) => setEndIndex(val)}
          dataPerPage={10}
          manageUsers
        />}
      </div>
    </>
  );
};

export default ManageUsers;
