import React from 'react'
import moment from 'moment';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { formatDate } from '../../helper/formatDate';

const FeesDetails = ({eventDetails,onEditClick}) => {
  return (
    <div className="col-lg-6 mainDivLive" id="sndDiv">
                  <span className="eventDetailsBlock"> Fees</span>
                  <BorderColorIcon className="editIcon" onClick={onEditClick} />
                  <div className="eventsHr" style={{ marginBottom: '17px', height: '1.1px' }}></div>

                  <div className="mainfee">Family Fee and Family Max</div>
                  <div className="detailsHeading">Family Fee:</div>
                  <span className="LiveData"> {eventDetails?.fees[0]?.familyfee}</span>

                  <div className="detailsHeading">Family max:</div>
                  <span className="LiveData">{eventDetails?.fees[0]?.familymax} </span>

                  <div className="mainfee" style={{ marginBottom: '10px' }} >Early and Late Fees</div>
                  <div className="d-flex justify-content-space-between latefee">
                    <div>
                      <span className="mainfee" style={{ marginTop: '10px' }}> Early fee:</span>
                    </div>
                    <div>
                      <span className="mainfee" style={{ marginTop: '10px' }}> Late fee</span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-space-between latefee">
                    <div>
                      <div>
                        <div className="detailsHeading">Family Less:</div>
                        <span className="LiveData">{eventDetails?.fees[0]?.earlyFee?.familyLess}</span>
                      </div>

                      <div className="detailsHeading">If date is before:</div>
                      <div>
                        {eventDetails?.fees[0]?.earlyFee?.ifDateIsBefore &&
                          <span className="LiveData">{moment(formatDate(eventDetails?.fees[0]?.earlyFee?.ifDateIsBefore)).format('MM/DD/YYYY')}</span>
                        }
                      </div>
                    </div>
                    <div>
                      <div className="detailsHeading" >Additional:</div>
                      <span className="LiveData">{eventDetails?.fees[0]?.lateFee?.additionalFee}</span>

                      <div className="detailsHeading">Late Fee After Date :</div>
                      {eventDetails?.fees[0]?.lateFee?.afterDate &&
                        <span className="LiveData">{moment(formatDate(eventDetails?.fees[0]?.lateFee?.afterDate)).format('MM/DD/YYYY')}</span>
                      }
                    </div>
                  </div>

                  <div className="mainfee" style={{ marginTop: '10px' }}>Late Fee After Closed</div>
                  <div className="d-flex justify-content-space-between latefee">
                    <div>
                      <div>
                        <div className="detailsHeading">Additional:</div>
                        <span className="LiveData">{eventDetails?.fees[0]?.lateFeeAfterClosed?.additionalFee}</span>
                      </div>

                      <div className="detailsHeading"> Late fee after closed Registration : </div>
                      {eventDetails?.fees[0]?.lateFeeAfterClosed?.afterDate &&
                        <span className="LiveData">{moment(formatDate(eventDetails?.fees[0]?.lateFeeAfterClosed?.afterDate)).format('MM/DD/YYYY')}</span>
                      }
                    </div>
                  </div>
                </div>
  )
}

export default FeesDetails