import React from 'react'
import { useNavigate } from 'react-router-dom'
import Back from '../../../Components/Back/Back'
import { backHandler } from '../../../helper/CreateEventBack'

const CreateEventTitle = ({ from, eventName, titleLoading, ID }) => {
    const navigate = useNavigate()

    const eventTitle = from === "feis" ? "New Feiseanna" : "New Grade Exam"
    return (
        <div className="FiesOrgMain">
            <div className="headingOrg">
                <div className="d-flex backContainer" style={{ color: '#68AD3F', cursor: 'pointer' }}>
                    <Back onBackClick={() => backHandler(ID, navigate, eventName, from)} />
                    {!titleLoading && <div className="organizerExam" style={{}}>{eventName ? eventName : eventTitle}</div>}
                </div>
            </div>
        </div>
    )
}

export default CreateEventTitle