import React, { useState, useEffect } from 'react';
import { serviceConsumer } from '../../network/ServiceConsumer';
import Loader from '../../Components/Loader/Loader';
import Card from '../../Components/CardOrg/Card';
import { useNavigate } from 'react-router-dom';
import PaginationNew from '../../Components/Pagination/PaginationNew';

const Events = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [feis, setFeis] = useState([]);
  const [grade, setGrade] = useState([]);
  const feisPage = localStorage.getItem("feisPage") ? localStorage.getItem("feisPage") : "1"
  const [pageFeis, setPageFeis] = useState(feisPage);
  const [totalPagesFeis, setTotalPagesFeis] = useState(0);
  const [startIndexFeis, setStartIndexFeis] = useState(0);
  const [endIndexFeis, setEndIndexFeis] = useState(0);
  const gradePage = localStorage.getItem("gradePage") ? localStorage.getItem("gradePage") : "1"
  const [pageGrade, setPageGrade] = useState(gradePage);
  const [totalPagesGrade, setTotalPagesGrade] = useState(0);
  const [startIndexGrade, setStartIndexGrade] = useState(0);
  const [endIndexGrade, setEndIndexGrade] = useState(0);
  localStorage.removeItem("closed")

  //navigations
  const navigateToFeisManage = async (event) => {

    localStorage.setItem("eventsTab", true);
    localStorage.setItem("organizerId", event.organizer_id)
    navigate(`/feis/manage?id=${event?._id}&Manage=feis`, {
      state: { eventProp: event },
    });
    localStorage.setItem('Manage', 'feis');
  };
  const navigateToGradeManage = async (event) => {
    localStorage.setItem("organizerId", event.organizer_id)
    navigate(`/grade/manage?id=${event?._id}&Manage=grade`, {
      state: { eventProp: event },
    });
    localStorage.setItem('Manage', 'grade');
  };

  //get open events of type feiseanna
  const getOpenFeis = async () => {
    try {
      setLoading(true);
      let payload = {
        page: pageFeis,
        limit: 5,
      };
      const url = `${process.env.REACT_APP_BASE_URL}/api/admin/feis/events`;
      const response = await serviceConsumer('POST', url, payload);
      let totalData = response?.totalCount;
      let totalPage = Math.ceil(Number(totalData / 5));
      setTotalPagesFeis(totalPage);
      let startindex = pageFeis % 10 === 0 ? pageFeis - 9 : Math.floor(pageFeis / 10) * 10 + 1;
      setStartIndexFeis(startindex);
      startindex + 9 > totalPage ? setEndIndexFeis(totalPage) : setEndIndexFeis(startindex + 9);
      if (response?.Feis?.results?.length > 0) {
        let data = response?.Feis?.results;
        setFeis(data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //get open events of type grade
  const getOpenGrade = async () => {
    try {
      setLoading(true);
      let payload = {
        page: pageGrade,
        limit: 5,
      };
      const url = `${process.env.REACT_APP_BASE_URL}/api/admin/grade/events`;
      const response = await serviceConsumer('POST', url, payload);
      let totalData = response?.totalCount;
      let totalPage = Math.ceil(Number(totalData / 5));
      setTotalPagesGrade(totalPage);
      let startindex = pageGrade % 10 === 0 ? pageGrade - 9 : Math.floor(pageGrade / 10) * 10 + 1;
      setStartIndexGrade(startindex);
      startindex + 9 > totalPage ? setEndIndexGrade(totalPage) : setEndIndexGrade(startindex + 9);
      if (response?.Feis?.results?.length > 0) {
        let data = response?.Feis?.results;
        setGrade(data);
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  };


  useEffect(() => {
    props.feis && getOpenFeis();
  }, [pageFeis]);

  useEffect(() => {
    !props.feis && getOpenGrade();
  }, [pageGrade]);

  return (
    <div className="db-body pb-5">
      {loading && <Loader />}
      {feis.length > 0 && props.feis && (
        <>
          <div className="db-org-title pt-4">Feisanna </div>
          <div className="mt-1 hr-org-body" />
          {feis.map((event) => (
            <Card
              key={event?._id}
              logo={event?.logo}
              eventType={event?.eventType}
              eventName={event?.name}
              date={event?.feis_date}
              venue={event?.venue_city}
              state={event?.state}
              onClick={() => navigateToFeisManage(event)}
              btnName="View"
              orgName={event?.usersData[0]?.fname}
            />
          ))}
          <PaginationNew
            loading={loading}
            startIndex={startIndexFeis}
            endIndex={endIndexFeis}
            totalPages={totalPagesFeis}
            page={pageFeis}
            setPage={(val) => setPageFeis(val)}
            setStartIndex={(val) => setStartIndexFeis(val)}
            setEndIndex={(val) => setEndIndexFeis(val)}
            dataPerPage={5}
            event="feisPage"
          />
        </>
      )}

      {grade.length > 0 && !props.feis && (
        <>
          <div className="db-org-title pt-4">Grade Exam </div>
          <div className="mt-1 hr-org-body" />
          {grade.map((event) => (
            <Card
              key={event?._id}
              logo={event?.logo}
              eventType={event?.eventType}
              eventName={event?.name}
              date={event?.exam_date}
              venue={event?.venue_city}
              state={event?.state}
              onClick={() => navigateToGradeManage(event)}
              btnName="View"
              orgName={event?.usersData[0]?.fname}
            />
          ))}
          <PaginationNew
            loading={loading}
            startIndex={startIndexGrade}
            endIndex={endIndexGrade}
            totalPages={totalPagesGrade}
            page={pageGrade}
            setPage={(val) => setPageGrade(val)}
            setStartIndex={(val) => setStartIndexGrade(val)}
            setEndIndex={(val) => setEndIndexGrade(val)}
            dataPerPage={5}
            event="gradePage"
          />
        </>

      )}
      {!loading && feis.length === 0 && grade.length === 0 && (
        <div className=" py-5 text-center">No events.</div>
      )}

    </div>
  );
};

export default Events;
