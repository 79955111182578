import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Modal from '@mui/material/Modal';
import closeIcon from '../../../Assets/close-icon.svg';
import createNew from '../../../Assets/create-new-event.svg';
import createNewEnabled from '../../../Assets/create-new-enabled.svg';
import prevEvent from '../../../Assets/prev-event.svg';
import prevEventEnabled from '../../../Assets/prev-event-enabled.svg';
import "../../../Organizer/GradeEventstyle.css";
import { serviceConsumer } from '../../../network/ServiceConsumer';
import Loader from '../../Loader/Loader';

const CreateEventModal = ({ modalState, clickHandler, eventType }) => {
  const [enableButton, setEnableButton] = useState(false);
  const [activeButton, setActiveButton] = useState('');
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [organizers, setOrganizers] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [openOptions, setOpenOptions] = useState(false)
  const [organizerId, setOrganizerId] = useState("")

  const navigate = useNavigate();

  const modalCloseHandler = () => {
    clickHandler();
    setEnableButton(false);
  };

  const getAllOrganizers = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}/api/admin/list-users`;
      const payload = {
        useType: "organizer",
        page,
        limit: 10,
      };
      const response = await serviceConsumer("POST", url, payload);
      const newOrganizers = response.date.list;
      if (organizers?.length > 0) {
        setOrganizers(prevOrganizers => [...prevOrganizers, ...newOrganizers]);
      } else {
        const defaultOrganizer = { _id: "", email: "You", fname: "Admin" };
        setOrganizers([defaultOrganizer, ...newOrganizers]);
      }
      setHasMore(response.date.hasResult);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hasMore) {
      getAllOrganizers();
    }
  }, [page, hasMore]);

  const createNewHandler = () => {
    setActiveButton('createNew');
    setEnableButton(true);
    localStorage.removeItem('ID');
    localStorage.removeItem('TAB');
    localStorage.setItem("redirectFeis", "create");
  };

  const createFromPrevHandler = () => {
    setActiveButton('createFromPrev');
    setEnableButton(true);
  };

  const nextButtonHandler = () => {
    localStorage.setItem("organizerId", organizerId)
    if (activeButton === 'createFromPrev') {
      navigate('/previous-events', { state: { eventType } });
    } else if (activeButton === 'createNew') {
      if (eventType === 'Feiseanna') {
        navigate('/feis/create');
      } else if (eventType === 'Grade Exam') {
        navigate('/grade/create');
      }
    }
  };

  const selectOptionsHandler = (selectedOption) => {
    setOrganizerId(selectedOption._id)
    setSelectedOrg(selectedOption);
    setOpenOptions(!openOptions)
  };

  const getEllipsedEmail = () => {
    const selectedEmail = `${selectedOrg.fname} (${selectedOrg.email})`
    if (selectedEmail?.length > 20) return `${selectedEmail.slice(0, 20)}...`
    else return selectedEmail
  }
  const handleScroll = (event) => {
    const div = event.target;
    if (Math.round(div.scrollHeight - div.scrollTop) === Math.round(div.clientHeight)) {
      setPage(prevPage => prevPage + 1);
    }
  };
  return (
    <div>
      {loading && <Loader />}
      <Modal sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} open={modalState}>
        <div className="org-modal-div">
          <div className="close-div mb-1">
            <img src={closeIcon} alt="close-icon" className="close-icon" onClick={modalCloseHandler} />
          </div>
          <p className="modal-org-title text-center">{eventType}</p>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className='mb-3' style={{ width: '200px', position: "relative" }}>
              <div className="dashboard-select" style={{ padding: "5px 0px 0 6px", fontSize: "13px" }} onClick={() => setOpenOptions(!openOptions)}>{selectedOrg ? getEllipsedEmail() : "Admin (You)"}</div>
              {openOptions &&
                <div className="org-dropdown" onScroll={handleScroll}>
                  {organizers.map(item => <div className="org-options" key={item?.email} onClick={() => selectOptionsHandler(item)}>{item.fname}&nbsp;({item.email})</div>)}
                </div>}
            </div>
            <div className="create-event-btns" onClick={createNewHandler}>
              <img src={activeButton === 'createNew' ? createNewEnabled : createNew} alt="" />
              <div>Create New</div>
            </div>
            <div className="create-event-btns" onClick={createFromPrevHandler}>
              <img src={activeButton === 'createFromPrev' ? prevEventEnabled : prevEvent} alt="" />
              <div>Previous Event</div>
            </div>
            <button
              className={enableButton ? 'filled mt-3 mb-5' : 'disabled mt-3 mb-5'}
              disabled={!enableButton}
              onClick={nextButtonHandler}
            >
              Next
            </button>
          </div>
        </div>
      </Modal >
    </div >
  );
};

export default CreateEventModal;
