import React, { useState, useEffect } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { Form, Formik, Field, ErrorMessage, FieldArray, } from 'formik';
import * as Yup from 'yup';
import AddIcon from '@mui/icons-material/Add';
import { serviceConsumer } from '../../network/ServiceConsumer';
import Loader from '../../Components/Loader/Loader';
import { sortGrades } from '../../helper/SortSyllabus';

const SyllabusOrg = (props) => {
  const [sylabusini, setsyllabusini] = useState([]);
  const [loading, setloading] = useState(false);
  const eventId = localStorage.getItem('ID');

  // get apde
  const getSyllabus = async () => {
    try {
      setloading(true);
      const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/fees/${eventId}`;
      const response = await serviceConsumer('GET', url);
      setsyllabusini(sortGrades(response.grade_exam_fees));
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };
  useEffect(() => {
    getSyllabus();
  }, []);

  const initialValues =
    sylabusini.length > 0
      ? {
        syllabe: sylabusini.map((item) => ({
          _id: item._id,
          grade_name: item.grade_name,
          grade_fee: item.grade_fee,
        })),
      }
      : {
        syllabe: [
          {
            grade_name: '',
            grade_fee: '',
          },
        ],
      };

  const DeleteGrade = async (item, remove, index, rest) => {
    if (item?._id) {
      try {
        setloading(true);
        const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/grade-exam-fee/${item._id}`;
        await serviceConsumer('DELETE', url);
        remove(index);
        remove(index);
        setTimeout(() => {
          setloading(false);
        }, 2000);
        window.location.reload();
      } catch (error) {
        setloading(false);
      }
    } else {
      remove(index);
      setloading(false);
    }
  };
  // validation
  const validationSchema = Yup.object().shape({
    syllabe: Yup.array().of(
      Yup.object().shape({
        grade_name: Yup.string().required('Grade is required'),
        grade_fee: Yup.string().required('Price is required'),
      })
    ),
  });

  // final submit
  const syllabusFinalHandler = async (values) => {
    try {
      setloading(true);
      const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/save-grade-exam-fee`;
      let page = localStorage.getItem('page') > 5 ? localStorage.getItem('page') : 5;
      await serviceConsumer('POST', url, {
        page: page,
        gradeId: eventId,
        data: values.syllabe,
      });
      if (Number(localStorage.getItem('page')) < 5) {
        localStorage.setItem('page', 5);
      }
      localStorage.setItem('TAB', 6);
      props.setValue('6');
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };
  const handleWheel = (e) => {
    e.preventDefault();
    e.currentTarget.blur(); // Remove focus from the input field
  };

  const handleKeyPress = (event) => {
    // Prevent 'e' character from being entered
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^\d$/.test(keyValue) && keyCode !== 8) {
      event.preventDefault();
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      e.currentTarget.blur(); // Remove focus from the input field
    }
  };
  // submitHandler
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div>
          <div className="detailsHeaders">
            <h4 className="eventPage">Complete Grades</h4>
            <p className="eventDescription">
              Add all of the grades you will be offering
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={syllabusFinalHandler}
          >
            {({ values, errors, touched, handleSubmit, isValid, dirty, ...rest }) => (
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <FieldArray name="syllabe">
                  {({ insert, remove, push }) => (
                    <div>
                      {values.syllabe.map((item, index) => (
                        <div key={item?._id}>
                          <div className="row" style={{ width: 'fit-content', gap: '24px' }}>
                            <div className="col">
                              <div className={` ${errors.syllabe?.[index]?.grade_name && touched.syllabe?.[index]?.grade_name ? 'd-flex flex-column' : 'input-control d-flex flex-column'}`}>
                                <label className={` ${errors.syllabe?.[index]?.grade_name && touched.syllabe?.[index]?.grade_name ? 'label-head-org-error' : 'label-head-org'}`} htmlFor={`syllabe.${index}.grade_name`}>
                                  Grade:
                                </label>
                                <Field
                                  className={` ${errors.syllabe?.[index]?.grade_name && touched.syllabe?.[index]?.grade_name ? 'input-box-org-error' : 'input-box-org'}`}
                                  type="text"
                                  name={`syllabe.${index}.grade_name`}
                                  placeholder="Grade"
                                  onChange={(e) => {
                                    rest.setFieldValue(`syllabe.${index}.grade_name`, e.target.value);
                                    rest.setFieldTouched(`syllabe.${index}.grade_name`, true);
                                  }}
                                />
                                <ErrorMessage className="auth-error-syl" name={`syllabe.${index}.grade_name`} component="div" />
                              </div>
                            </div>
                            <div className={` ${errors.syllabe?.[index]?.grade_fee && touched.syllabe?.[index]?.grade_fee ? 'col' : 'input-control col'}`}>
                              <label className={` ${errors.syllabe?.[index]?.grade_fee && touched.syllabe?.[index]?.grade_fee ? 'label-head-org-error' : 'label-head-org'}`} htmlFor={`syllabe.${index}.grade_fee`}>
                                Price:
                              </label>
                              <div className="d-flex iconsave">
                                <div>
                                  <Field
                                    className={` ${errors.syllabe?.[index]?.grade_fee && touched.syllabe?.[index]?.grade_fee ? 'input-box-org-error' : 'input-box-org'}`}
                                    type="number"
                                    autoComplete="off"
                                    name={`syllabe.${index}.grade_fee`}
                                    placeholder="Price"
                                    onWheel={handleWheel}
                                    onKeyPress={handleKeyPress}
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => {
                                      rest.setFieldValue(`syllabe.${index}.grade_fee`, e.target.value);
                                      rest.setFieldTouched(`syllabe.${index}.grade_fee`, true);
                                    }}
                                  />
                                  <ErrorMessage className="auth-error-syl" name={`syllabe.${index}.grade_fee`} component="div" />
                                </div>
                                <div>
                                  <DeleteOutlineIcon
                                    // className={!enableDlt ? 'dltSyllabus' : 'dltSyllabus opacity-50 pe-none'}
                                    className='dltSyllabus'
                                    onClick={() => DeleteGrade(item, remove, index, rest)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <Button
                        onClick={() => { push({ grade_name: '', grade_fee: '' }); }}
                        variant="outlined"
                        className={isValid ? "plusIconbtn" : 'plusIconbtn opacity-50 pe-none'}
                        color="primary"
                        sx={{
                          my: '20px',
                          fontSize: '16px',
                          marginTop: '30px',
                          border: '2px solid',
                          '&:hover': {
                            border: '2px solid #fbb040',
                            background: ' #fbb040 ',
                            color: '#ffffff',
                            borderRadius: '2px solid #fbb040',
                          },
                        }}
                        type="button"
                      >
                        <AddIcon id="plusICon" />
                        <span style={{ marginTop: '2px' }}>Add Grade</span>
                      </Button>
                    </div>
                  )}
                </FieldArray>
                <div className="mb-5">
                  <button
                    type="submit"
                    className={isValid ? "filled" : "disabled"}
                  >
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <ToastContainer />
        </div >
      )}
    </>
  );
};
export default SyllabusOrg;
