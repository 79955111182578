import React, { useState } from 'react'
import Popover from '@mui/material/Popover';
import Radio from '../../Components/Radio/Radio';
import filterIcon from "../../Assets/filter.svg"

const SchoolFilters = ({ filter, setFilter }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <img className="cursor-pointer" src={filterIcon} alt="filter" onClick={handleFilterClick} />
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleFilterClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ marginTop: '5px', marginLeft: 0 }}
            >
                <div
                    className=" ps-3 py-2 px-3 d-flex align-items-center justify-content-between create-event-opt"
                    onClick={() => setFilter("asc")} >
                    A-Z
                    <Radio className="mb-3" checked={filter === "asc"} />
                </div>
                <div
                    className=" ps-3 py-2 px-3 d-flex align-items-center justify-content-between create-event-opt"
                    onClick={() => setFilter("desc")}>
                    Z-A
                    <Radio className="mb-3" checked={filter === "desc"} />
                </div>
                <div
                    className=" ps-3 py-2 px-3 d-flex align-items-center justify-content-between create-event-opt"
                    onClick={() => setFilter("latest")}>
                    Latest
                    <Radio className="mb-3" checked={filter === "latest"} />
                </div>
                <div
                    className=" ps-3 py-2 px-3 d-flex align-items-center justify-content-between create-event-opt"
                    onClick={() => setFilter("oldest")}>
                    Oldest
                    <Radio className="mb-3" checked={filter === "oldest"} />
                </div>
            </Popover>
        </>
    )
}

export default SchoolFilters
