const sortDescriptionOrder = {
    "REEL": 1,
    "LIGHT JIG": 2,
    "SINGLE JIG": 3,
    "SLIP JIG": 4,
    "TREBLE JIG": 5,
    "HORNPIPE": 6,
    "TRAD. SET": 7
}

export const sortLevels = (levelData) => {
    const customSortOrder = [
        "BEGINNER",
        "ADVANCED BEGINNER",
        "NOVICE",
        "PRIZEWINNER",
        "PRELIMINARY CHAMPIONSHIPS",
        "OPEN CHAMPIONSHIPS",
        "TEAMS",
        "ARTS AND CULTURE",
        "Non-Level"
    ];

    const sortedKeys = Object.keys(levelData).sort((a, b) => {
        const indexA = customSortOrder.indexOf(a);
        const indexB = customSortOrder.indexOf(b);

        // If a or b is not in customSortOrder, move it to the end
        if (indexA === -1 && indexB === -1) {
            return 0; // Keep the original order for items not in customSortOrder
        } else if (indexA === -1) {
            return 1; // Move items not in customSortOrder to the end
        } else if (indexB === -1) {
            return -1; // Move items not in customSortOrder to the end
        }

        return indexA - indexB;
    });

    const sortedLevels = {};
    sortedKeys.forEach((key) => {
        sortedLevels[key] = levelData[key];
    });

    return sortedLevels;
};

export const sortLevelData = (levelData) => {
    //considering levels are already sorted

    //sorting based on description
    levelData?.forEach(item => {
        if (!(item._id === "SPECIALS" || item._id === "TEAMS")) {
            item?.competitions?.sort((a, b) => {
                const orderA = sortDescriptionOrder[a.description] || 8;
                const orderB = sortDescriptionOrder[b.description] || 8;
                if (orderA === orderB) {
                    const ageA = parseInt(a.ages[0].length <= 3 ? a.ages[0].slice(1) : "25")
                    const ageB = parseInt(b.ages[0].length <= 3 ? b.ages[0].slice(1) : "25");
                    return ageA - ageB;
                }
                return orderA - orderB;
            })
        }
    })
    return levelData;
}

export const sortCompetitions = (competitions) => {
    competitions?.sort((a, b) => {
        const orderA = sortDescriptionOrder[a.description] || 8;
        const orderB = sortDescriptionOrder[b.description] || 8;
        if (orderA === orderB) {
            const ageA = parseInt(a.ages[0].length <= 3 ? a.ages[0].slice(1) : "25")
            const ageB = parseInt(b.ages[0].length <= 3 ? b.ages[0].slice(1) : "25");
            return ageA - ageB;
        }
        return orderA - orderB;
    })
    return competitions;
}

export const groupAndSortComp = (competitions, isDesc) => {
    const organizedCompUnderLevels = {};

    competitions?.forEach(comp => {
        const groupName = comp.group;

        if (!organizedCompUnderLevels[groupName]) {
            organizedCompUnderLevels[groupName] = [];
        }

        organizedCompUnderLevels[groupName].push(comp);
    });

    const sortedLevels = sortLevels(organizedCompUnderLevels)

    let sortedComp = [];
    Object.keys(sortedLevels)?.forEach(levelKey => {
        const sortedCompetitions = sortCompetitions(sortedLevels[levelKey])
        sortedComp = [...sortedComp, ...sortedCompetitions]
    })
    if (isDesc) {
        sortedComp.reverse();
    }
    return sortedComp;
}

export const sortGrades = (grades) => {
    grades?.sort((a, b) => {
        // Extract the numeric part of grade_name
        let numA = extractNumber(a.grade_name);
        let numB = extractNumber(b.grade_name);

        // If no number is present, sort alphabetically
        if (isNaN(numA) && isNaN(numB)) {
            return a.grade_name.localeCompare(b.grade_name);
        }

        // If one of them doesn't have a number, consider it smaller
        if (isNaN(numA)) return 1;
        if (isNaN(numB)) return -1;

        // Compare numeric parts
        return numA - numB;
    });

    function extractNumber(grade_name) {
        // Extract the numeric part of grade_name
        let match = grade_name.match(/\d+/);
        if (match) {
            return parseInt(match[0]);
        }
        return NaN;
    }

    return grades
}
