import axios from 'axios';

export function serviceConsumer(type, url, body = null, returnFilter = "", resType = "") {
  const getServerError = (err, msg) => {
    return { status: false, statuscode: 500, messages: msg, error: err }
  }

  const getMessage = (response) => {
    if (response.messages) return response.messages
    else if (response.message) return response.message
    else return 'ServerError'
  }
  const getErrMessage = (err, response) => {
    return {
      status: false,
      statuscode: response.status,
      messages: getMessage(response),
      error: err
    };
  }

  const getResponseType = () => {
    if (resType === "buffer") return 'arraybuffer'
    else if (resType === "file") return 'multipart/form-data'
    else return ''
  }
  const getFilteredResponse = (response) => {

    if (returnFilter) return response.data[returnFilter]
    else if (resType === "buffer") return response
    else return response.data
  }

  if (url) {
    if (body != null) {
      if (!(body instanceof FormData))
        body = JSON.parse(JSON.stringify(body).replace(/"\s+|\s+"/g, '"'));
    }
    let authentiction = true;

    if (authentiction) {
      const axiosOptions = {
        headers: {
          Authorization: "Basic " + window.btoa(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`),
          accesstoken: localStorage.getItem("accessToken"),
        },
      };
      //used for get & delete
      let optionsdata = {
        params: body,
        headers: axiosOptions.headers
      };;
      if (type === 'GET') {
        return axios.get(url, optionsdata).then(response => {
          if (response.status === 400 || response.status === 500)
            throw response.data;
          return returnFilter ? response.data[returnFilter] : response.data;
        }).catch(err => {
          if (err.response) {
            // Request made and server responded
            let response = err.response.data;
            let apiThrowResponse = getErrMessage(err, response)
            throw apiThrowResponse;
          } else if (err.request) {
            // The request was made but no response was received
            let apiThrowResponse = getServerError(err, "Server Error");
            throw apiThrowResponse;
          } else {
            // Something happened in setting up the request that triggered an Error
            let apiThrowResponse = getServerError(err, "Please check your internet connection");
            throw apiThrowResponse;
          }
        });
      }

      else if (type === "PUT") {
        return axios.put(url, body, axiosOptions).then(response => {
          if (response.status === 400 || response.status === 500)
            throw response.data;
          return returnFilter ? response.data[returnFilter] : response.data;;
        }).catch(err => {
          if (err.response) {
            // Request made and server responded
            let response = err.response.data;
            let apiThrowResponse = getErrMessage(err, response)
            throw apiThrowResponse;
          } else if (err.request) {
            // The request was made but no response was received
            let apiThrowResponse = getServerError(err, "Server Error");
            throw apiThrowResponse;
          } else {
            // Something happened in setting up the request that triggered an Error
            let apiThrowResponse = getServerError(err, "Please check your internet connection ");
            throw apiThrowResponse;
          }
        });
      }

      else if (type === "POST") {
        return axios.post(url, body, { ...axiosOptions, responseType: getResponseType() }).then(response => {
          if (response.status === 400 || response.status === 500)
            throw response.data;
          const filteredPostResponse = getFilteredResponse(response)
          return filteredPostResponse
        }).catch(err => {
          if (err.response) {
            // Request made and server responded
            let response = err.response.data;
            let apiThrowResponse = getErrMessage(err, response)
            throw apiThrowResponse;
          } else if (err.request) {
            // The request was made but no response was received
            let apiThrowResponse = getServerError(err, "Server Error");
            throw apiThrowResponse;
          } else {
            // Something happened in setting up the request that triggered an Error
            let apiThrowResponse = getServerError(err, "Please check your internet connection");
            throw apiThrowResponse;
          }
        });
      }

      else if (type === "DELETE") {
        return axios.delete(url, optionsdata).then(response => {
          if (response.status === 400 || response.status === 500)
            throw response.data;
          return returnFilter ? response.data[returnFilter] : response.data;
        }).catch(err => {
          if (err.response) {
            // Request made and server responded
            let response = err.response.data;
            let apiThrowResponse = getErrMessage(err, response)
            throw apiThrowResponse;
          } else if (err.request) {
            // The request was made but no response was received
            let apiThrowResponse = getServerError(err, "Server Error");
            throw apiThrowResponse;
          } else {
            // Something happened in setting up the request that triggered an Error
            let apiThrowResponse = getServerError(err, "Please check your internet connection");
            throw apiThrowResponse;
          }
        });
      }

      else {
        let apiThrowResponse = { status: false, statuscode: 401, messages: 'please mention type of the request' };
        throw apiThrowResponse;
      }
    }

    else {
      let apiThrowResponse = { status: false, statuscode: 401, messages: 'you are not aunthorized' };
      throw apiThrowResponse;
    }

  }
  else {
    let apiThrowResponse = { status: false, statuscode: -1, messages: 'rquest Url is not found' };
    throw apiThrowResponse;
  }
}