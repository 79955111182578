import React, { useState, useEffect } from 'react';
import HeaderOrg from '../Headers/HeaderOrg';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useNavigate } from 'react-router-dom';
import { serviceConsumer } from '../../network/ServiceConsumer';
import Loader from '../../Components/Loader/Loader';
import Help from '../../Components/Help/Help';
import GoLiveTitle from './GoLiveTitle';
import EventDetailsSection from './EventDetailsSection';
import VenueDetailsSection from './VenueDetailsSection';
import AboutDetails from './AboutDetails';
import FeesDetails from './FeesDetails';
import { renderAccommodation, renderCollab } from './renderFns';
import { getCollabdetails } from './getCollabdetails';

const FinalFiezDetails = ({ type }) => {
  const [loading, setLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState();
  const [adjucator, setAdjucator] = useState([]);
  const [examiner, setExaminer] = useState([]);
  const [musician, setMusician] = useState([]);
  const [sponsor, setSponser] = useState([]);
  const [accomodation, setaccomodation] = useState([eventDetails?.accomodations,]);

  let EventID = localStorage.getItem('ID');
  const navigate = useNavigate();

  useEffect(() => {
    getEventDetails();
  }, []);

  //  to get eventDetails on back
  useEffect(() => {
    setaccomodation(eventDetails?.accomodations);
  }, [eventDetails]);

  const getEventDetails = async () => {
    setLoading(true);

    try {
      if (!EventID) return;

      const feisUrl = `${process.env.REACT_APP_BASE_URL}/feis/${EventID}`;
      const gradeUrl = `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}`;
      const url = type === "feis" ? feisUrl : gradeUrl;

      const response = await serviceConsumer('GET', url);
      const resForType = type === "feis" ? response.Feis : response.grade_exams;

      setEventDetails(resForType);

      if (!resForType) return;

      const { adjudicators, musicians, sponsors, examiners } = resForType;
      const promises = [];

      if (adjudicators?.length > 0) getCollabdetails(adjudicators, setAdjucator, promises);
      if (examiners?.length > 0) getCollabdetails(examiners, setExaminer, promises);
      if (musicians?.length > 0) getCollabdetails(musicians, setMusician, promises);
      if (sponsors?.length > 0) getCollabdetails(sponsors, setSponser, promises);

      await Promise.all(promises);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const editHandler = (id) => {
    if (!eventDetails.live) localStorage.setItem("redirectFeis", "goLive")
    localStorage.setItem('TAB', id);
    type === "feis" ? navigate('/feis/create') : navigate('/grade/create');;
  };

  return (
    <>
      <HeaderOrg />
      {loading && <Loader />}
      {!loading && (
        <div className="goLiveMainDIv">
          <GoLiveTitle eventDetails={eventDetails} type={type === "feis" ? "Feisanna" : "Grade Exam"} />
          <div className="goLiveMAin"></div>
          <section className='golive-section'>
            <div className="container pb-5">
              <div className="row align-items-start ROwLive">
                <EventDetailsSection eventDetails={eventDetails} onEditClick={() => editHandler(1)} type={type} />
                <VenueDetailsSection eventDetails={eventDetails} onEditClick={() => editHandler(2)} />
              </div>
              <div className="row align-items-start ROwLive">
                <AboutDetails eventDetails={eventDetails} onEditClick={() => editHandler(6)} />
                <FeesDetails eventDetails={eventDetails} onEditClick={() => editHandler(4)} />
              </div>

              {/* collaborator details */}
              {adjucator?.length > 0 || examiner?.length > 0 || sponsor?.length > 0 || accomodation?.length > 0 ? (
                <div style={{ paddingBottom: '3rem' }}>
                  <div className="col-lg-6 mainDivLive ">
                    <span className="eventDetailsBlock">Complete Collaborators</span>
                    <BorderColorIcon className="editIcon" onClick={() => { editHandler(7); }} />
                    <div className="eventsHr" style={{ marginBottom: '17px' }}></div>

                    {adjucator?.length > 0 && renderCollab("Adjudicators:", adjucator)}

                    {examiner?.length > 0 && renderCollab("Examiners:", examiner)}

                    {sponsor?.length > 0 && renderCollab("Sponsors:", sponsor)}

                    {musician?.length > 0 && renderCollab("Musicians", musician)}

                    {accomodation?.length > 0 && renderAccommodation(accomodation)}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </section>
        </div>
      )}
      <Help />
    </>
  );
};

export default FinalFiezDetails;

