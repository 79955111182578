export const backHandler = (ID, navigate, eventName, type) => {


    if (localStorage.getItem("ID")) {
        redirectHandler(ID, navigate, type)
    } else {
        type === "feis" ? navigate("/feis") : navigate("/grade");

    }
};

const redirectHandler = (ID, navigate, type) => {
    const redirectFeis = localStorage.getItem("redirectFeis");
    const manage = localStorage.getItem("Manage");
    if (redirectFeis === "goLive") {
        localStorage.removeItem("redirectFeis")
        const path = type === "feis" ? "/feis/create/finalFeisDetails" : "/grade/create/finalGradeDetails"
        navigate(path)
    }
    else if (redirectFeis === "edit") {
        localStorage.removeItem("redirectFeis")
        navigate(`/event-details?id=${ID}`);
    } else if (redirectFeis === "create") {
        localStorage.removeItem("redirectFeis")
        navigate("/");

    } else if (manage) {
        const path = type === "feis" ? `/feis/manage?id=${ID}&Manage=feis` : `/grade/manage?id=${ID}&Manage=grade`;
        navigate(path);
    } else {
        type === "feis" ? navigate("/feis") : navigate("/grade");
    }
}