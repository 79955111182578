import React, { useState, useRef } from 'react'
import Button from '../../../../Button/Button';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AddButton from '../../../../AddButton/AddButton';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import closeIcon from "../../../../../Assets/close-icon.svg"
import Loader from '../../../../Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MODAL } from '../../../../../redux/actionTypes/modalActionType';
import { serviceConsumer } from '../../../../../network/ServiceConsumer';
import { handlePhotoUpload } from '../../../../../helper/handleUploads';
import { openSelectOldCollabModal } from '../../../../../helper/openSelectOldCollabModal';

const AddCollaboratorModal = ({ modalState, title, btnName }) => {
    const [loading, setLoading] = useState(false)
    const [imgErr, setImgErr] = useState("");
    const [logo, setLogo] = useState("");
    const [upl, setUpl] = useState("");
    const [err, setErr] = useState("")
    const fileInputRef = useRef()
    const dispatch = useDispatch();
    const modal = useSelector((state) => state.modalReducer)
    const eventId = modal.eventId
    const ManageType = localStorage.getItem('Manage');

    //Initial values for formik
    const addCollaboratorInitialValues = {
        name: "",
        location: "",
    };
    const getCollabName = () => {
        if (btnName === "adjudicator") return "adjudicator"
        else if (btnName === "musician") return "musician"
        else if (btnName === "Examiner") return "examiner"
        else return "sponsor"
    }
    //Validation Schema for Formik
    const addCollaboratorValidationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .label("fname")
            .required("This field is required.")
            .nullable()
            .max(30, "Maximum characters allowed 30"),
        location: Yup.string()
            .trim()
            .label("lname")
            .required("This field is required.")
            .nullable()
            .max(30, "Maximum characters allowed 30"),
    });

    //form submit handler-to add collaborator
    const addCollaboratorHandler = async (values) => {
        setLoading(true)
        const payload = {
            name: values.name,
            location: values.location,
            type: btnName,
            photo: logo,
            eventId: eventId,
        }
        const collaborator = getCollabName()

        try {
            const feisUrl = `${process.env.REACT_APP_BASE_URL}/people/${collaborator}`;
            const gradeUrl = `${process.env.REACT_APP_BASE_URL}/grade-exam/${collaborator}`;
            const url = ManageType === "feis" ? feisUrl : gradeUrl
            await serviceConsumer("POST", url, payload)
            openCollaboratorModal()
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
            setErr(error.messages)
        }
    }

    //add image on button click with input hidden //since input is hidden, point it with button click
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };
    const handleFileSelect = async (event) => {
        handlePhotoUpload(event, setLoading, setLogo, setUpl, setImgErr);
    };


    //on close modal, open previous modal
    const modalCloseHandler = () => openSelectOldCollabModal(title, eventId, "Sponsor", dispatch)

    //on submit, open collaborator modal
    const openCollaboratorModal = () => {
        dispatch({
            type: SET_MODAL,
            payload: {
                modal: `${title}s`,
                eventId: eventId,
            }
        })
    }

    return (
        <div>
            {loading && <Loader />}
            <Modal className="addDancerScroll" sx={{ display: "flex", alignItems: "center", justifyContent: "center", }} open={modalState}>
                <Box className="mui-box">
                    <div className='modal-box'>
                        <div className="org-modal-div">
                            <div className="close-div mt-4 mb-1">
                                <img src={closeIcon} alt="close-icon" className="close-icon" onClick={modalCloseHandler} />
                            </div>
                            <p className="color-subtitle text-center mb-4">Add {title}</p>
                            <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ background: "white,borderRadius: 14px;" }}
                            >
                                <div>
                                    <Formik
                                        initialValues={addCollaboratorInitialValues}
                                        validationSchema={addCollaboratorValidationSchema}
                                        onSubmit={addCollaboratorHandler}
                                    >
                                        {({ dirty, isValid }) => {
                                            return (
                                                <Form autoComplete="off">
                                                    <div>
                                                        <div className="d-flex flex-column">
                                                            <label className="label-head">Full name:</label>
                                                            <Field className="input-box" type="text" name="name" placeholder="Name" maxlength={30} />
                                                            <ErrorMessage className="auth-error" name="name" component="div" />
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <label className="label-head">Location:</label>
                                                            <Field className="input-box" type="text" name="location" placeholder="Location" maxlength={30} />
                                                            <ErrorMessage className="auth-error" name="location" component="div" />
                                                        </div>
                                                        <div className="label-head">Photo:</div>
                                                        <input
                                                            type="file"
                                                            ref={fileInputRef}
                                                            accept="image/*"
                                                            style={{ display: 'none' }}
                                                            onChange={handleFileSelect}
                                                        />
                                                        <div className="remove-margin">
                                                            <AddButton className="plusIconbtn" onClick={handleButtonClick} style={{ marginTop: "0" }}>Add Photo</AddButton>
                                                        </div>
                                                        {imgErr && <div className="error mt-2" style={{ width: "248px" }}>{imgErr}</div>}
                                                        {upl && <div className="auth-suc" style={{ width: "248px" }}>{upl}</div>}
                                                        {!loading && err && (<div style={{ color: 'red', textAlign: "center" }}> {err}</div>)}
                                                        <div className="d-flex justify-content-center mt-3 mb-5" >
                                                            <Button type="submit" className={isValid && dirty ? "filled " : "disabled "}>Add {btnName}</Button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div >
    )
}

export default AddCollaboratorModal