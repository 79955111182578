import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import HeaderOrg from '../../../Headers/HeaderOrg'
import Loader from '../../../../Components/Loader/Loader';
import ManageTitle from '../../../../Components/TitleElements/ManageTitle'

import { serviceConsumer } from '../../../../network/ServiceConsumer';
import EditRegisterSuccess from '../../../../Components/Modals/OrganizerModel/ManageFeis/Entries/EditRegisterSuccess';
import RegisteredGrade from './RegisteredGrade';


const GradeEditRegister = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [showPaymentPage, setshowPaymentPage] = useState(false);
    const [grades, setGrades] = useState([]);
    const [checkedStateNew, setCheckedStateNew] = useState([]);
    const dancerError = { id: 0, message: "" };
    const [eventDetails, setEventDetails] = useState();
    const [prevTotal, setPrevTotal] = useState(0);
    const [initialLoading, setInitialLoading] = useState(false)
    const [prevDancerTotal, setPrevDancerTotal] = useState(0);
    const [enable, setEnable] = useState(false)
    const [modal, setModal] = useState()
    const [payload, setPayload] = useState()
    const [clickPaymentButton, setClickPaymentButton] = useState(false)

    // all dancer in an event
    const [allDancer, setAllDancer] = useState([]);

    // selected level's competitions list for all dancer, here one dancer in edit flow
    const [selectedGrades, setSelectedGrades] = useState([]);

    // selected competitions list for all dancer, here one dancer in edit flow
    const [previousCompetition, setPreviousCompetition] = useState([]);

    const searchParams = new URLSearchParams(window.location.search)
    const regId = searchParams.get('regId')
    const eventId = searchParams.get('id')

    // get Registration Detail for 
    const getRegistrationDetail = async (grade_exam_fees) => {
        const url = `${process.env.REACT_APP_BASE_URL}/registration/grade/regdetails/${regId}`;
        try {
            setLoading(true);
            const response = await serviceConsumer('GET', url);
            if (response.result?.length) {
                const uniqueComp = [...new Set(response.result[0]?.grade_exam_fees)];
                setAllDancer([{
                    _id: response.result[0]?.dancer_id,
                    fname: response.result[0]?.dancerData?.fname,
                    lname: response.result[0]?.dancerData?.lname,
                    competitions: uniqueComp,
                }]);
                setPrevTotal(response.result[0]?.allLevelAmount)
                setPrevDancerTotal(response.result[0]?.dancerTotal)

                setCheckedStateNew([uniqueComp]);
                setPreviousCompetition(uniqueComp)

                const gradesList = grade_exam_fees.filter(grade => uniqueComp?.includes(grade._id))

                const dancerTotal = gradesList.reduce((res, { grade_fee }) => res + grade_fee, 0)

                const values = {
                    grades: gradesList,
                    dancer_id: response.result[0]?.dancer_id,
                    dancerTotal,
                    dancer_name: `${response.result[0]?.dancerData?.fname} ${response.result[0]?.dancerData?.lname}`
                };

                setSelectedGrades([values]);
            }
            setLoading(false);
        } catch (error) {
            navigate("/pageNotFound", { replace: true })
            setLoading(false);
        }
    };

    //navigating back to homepage if the url is misused
    useEffect(() => {
        getEventDetails();
        getAllGrades();
        document.title = "Feis.Link - Register Dancers"
    }, []);

    //  to get eventDetails
    const getEventDetails = async () => {
        setInitialLoading(true);
        try {
            if (eventId) {
                const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/${eventId}`;
                const response = await serviceConsumer('GET', url);
                setEventDetails(response.grade_exams);
            }
            setInitialLoading(false);

        } catch (error) {
            navigate("/pageNotFound", { replace: true })
            setInitialLoading(false);
        }
    };

    const getAllGrades = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/fees/${eventId}`;
        setInitialLoading(true);
        try {
            const response = await serviceConsumer('GET', url);
            if (response?.grade_exam_fees) {
                setGrades(response.grade_exam_fees);
                getRegistrationDetail(response?.grade_exam_fees)
            }

            setInitialLoading(false);
        } catch (error) {
            setInitialLoading(false);
        }
    }

    //checkbox and grade array handler
    const handleOnChange = async (index, dancer, grade) => {
        setLoading(true);
        const item = selectedGrades[index]?.grades?.find(it => it._id === grade._id);
        const dancer_name = `${dancer.fname} ${dancer.lname}`

        if (selectedGrades[index] && item) {
            removeGrade(index, dancer_name, dancer._id, grade._id)
        } else {
            let newSelectedList = [...selectedGrades];
            const gradesList = newSelectedList[index]?.grades?.length ? [...newSelectedList[index]?.grades, grade] : [grade]
            const dancerTotal = gradesList.reduce((res, { grade_fee }) => res + grade_fee, 0)
            const values = {
                grades: gradesList,
                dancer_id: dancer._id,
                dancerTotal,
                dancer_name: `${dancer.fname} ${dancer.lname}`
            };

            setSelectedGrades([values]);

            const newList = [...checkedStateNew];
            const checkedStateNewItem = newList[index] ? [...newList[index], grade._id] : [grade._id];
            newList[index] = checkedStateNewItem;
            setCheckedStateNew(newList);
        }

        setTimeout(() => {
            setLoading(false)
        }, 300);
    };

    const removeGrade = (index, dancer_name, dancer_id, gradeId) => {
        const newList = [...checkedStateNew];
        const checkedStateNewItem = newList[index]?.filter(item => item !== gradeId);
        newList[index] = checkedStateNewItem;
        setCheckedStateNew(newList);

        const newSelectedList = [...selectedGrades];
        const grades = selectedGrades[index]?.grades?.filter(item => item._id !== gradeId)
        const dancerTotal = grades.reduce((res, { grade_fee }) => res + grade_fee, 0)

        const values = { grades, dancer_id, dancer_name, dancerTotal };

        newSelectedList[index] = values;

        setSelectedGrades(newSelectedList);
    };


    const errormsg = (err) => {
        setshowPaymentPage(false);
        setLoading(false);
    };

    const saveChangeHandler = () => {
        setClickPaymentButton(true)
    }

    const editCandidate = async () => {
        const urlPayload = { ...payload, grade_exam_fees: payload.grade_exam_fee_id }

        delete urlPayload.serviceAmount;
        delete urlPayload.grade_exam_fee_id;
        delete urlPayload.dancerServiceAmount;

        try {
            const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/editcandidate`;
            setLoading(true);
            await serviceConsumer('POST', url, urlPayload);
            setModal("EditRegisterSuccess")
            setTimeout(() => { navigate(`/edit-candidates/${eventDetails?.name}?id=${eventId}`, { state: { eventName: eventDetails?.name } }) }, 1000)
            setLoading(false);
        } catch (error) {
            setLoading(false);

        }
    }

    useEffect(() => {
        payload && editCandidate()
    }, [payload])

    return (
        <div>
            <HeaderOrg />
            {(initialLoading || loading) && <Loader />}
            {!initialLoading && <ManageTitle
                eventLogo={eventDetails?.logo}
                eventName={eventDetails?.name}
                eventType={eventDetails?.eventType}
                eventDate={eventDetails?.exam_date}
                className={enable ? "filled" : "disabled"}
                button="Save Changes"
                onClick={saveChangeHandler}
            />}
            {modal === "EditRegisterSuccess" && <EditRegisterSuccess modalState={true} eventName={eventDetails?.name} />}

            {!initialLoading && <div className="row edit-register-org" >
                <div className="col-md-7 leftDiv editOrg-leftDiv">
                    <div className=" col-10 reg-left-container">
                        {allDancer?.map((dancer, index) => {
                            return (
                                <div key={dancer._id} className="my-5">
                                    <h5>
                                        Register dancer:&nbsp;
                                        <span style={{ color: "#68ad3f" }}>{dancer?.fname} {dancer?.lname}</span>
                                    </h5>
                                    <hr className="mt-0"></hr>
                                    <h6 style={{ color: "#68ad3f", marginTop: "10px", fontSize: "12px", }}>Choose Grade</h6>
                                    <div style={{ border: "2px solid #EAEAEA", padding: "5px", width: "fit-content", }} className="chosegradee">
                                        {grades?.map(grade => (
                                            <div
                                                className="btn-group btn-group-toggle action"
                                                data-toggle="buttons"
                                                style={{ margin: "5px" }}
                                                key={grade._id}
                                            >
                                                <label className={(checkedStateNew && checkedStateNew[index]?.includes(grade._id)) ? "btn activeBtn" : "btn"}>
                                                    <input
                                                        type="checkbox"
                                                        checked={(checkedStateNew && checkedStateNew[index]?.includes(grade._id)) ? true : false}
                                                        onChange={() => handleOnChange(index, dancer, grade)}
                                                    />
                                                    {grade?.grade_name}
                                                </label>{" "}
                                            </div>
                                        ))}
                                    </div>
                                    {dancer._id === dancerError.id && (<p className="error">{dancerError.message}</p>)}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="col-md-5 d-flex flex-column right-calc-div">
                    <RegisteredGrade
                        edit
                        org
                        regId={regId}
                        eventDetails={eventDetails}
                        selectedGrades={selectedGrades}
                        prevTotal={prevTotal}
                        removeGrade={removeGrade}
                        errormsg={errormsg}
                        showPaymentPage={showPaymentPage}
                        setEnable={setEnable}
                        previousCompetition={previousCompetition}
                        prevDancerTotal={prevDancerTotal}
                        setPayload={setPayload}
                        clickPaymentButton={clickPaymentButton}
                    />
                </div>
            </div>}
        </div>
    )
}

export default GradeEditRegister
