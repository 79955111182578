import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LOGOUT } from '../../redux/actionTypes/authActionType';

import Loader from '../../Components/Loader/Loader';
import "./Auth.css"
import Button from '../../Components/Button/Button';

import { serviceConsumer } from "../../network/ServiceConsumer"

import feisLogo from "../../Assets/feis-logo.svg";
import Help from '../../Components/Help/Help';
import { Alert } from '@mui/material';
import { passwordValidation, repeatPwdValidation } from '../../helper/Validations';

const ConfirmPasswordOrg = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const params = new URLSearchParams(window.location.search)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)

    const navigateToHomepageHandler = () => {
        navigate("/")
    }

    //Initial values for formik
    const confirmPasswordInitialValues = {
        password: "",
        repeatPassword: "",
    };

    //Validation Schema for Formik
    const confirmPasswordValidationSchema = Yup.object().shape({
        password: passwordValidation,
        repeatPassword: repeatPwdValidation,
    });

    const confirmPasswordHandler = async (values) => {
        const token = params.get("token")
        const id = params.get("id")
        const payload = {
            token: token,
            id: id,
            password: values.password
        }
        try {
            setLoading(true)
            const url = `${process.env.REACT_APP_BASE_URL}/api/admin/reset-pw`;
            const response = await serviceConsumer("POST", url, payload)
            if (response.success) {
                localStorage.clear();
                setSuccess(response.message);
                setTimeout(() => {
                    dispatch({
                        type: LOGOUT,
                    });
                }, 3000);
                setTimeout(() => navigate("/admin/login"), 1000)
            }
            setLoading(false)
        }
        catch (error) {
            setError(error.messages);
            setTimeout(() => setError(""), 3000);
            setLoading(false)
        }
    }

    return (
        <div className="d-flex flex-column justify-content-between p-4 auth-org" style={{ minHeight: "100vh" }}>
            {loading && <Loader />}
            <div>
                <img src={feisLogo} alt="feis-logo" onClick={navigateToHomepageHandler} style={{ cursor: "pointer" }} />
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center" >
                {success && <Alert sx={{ color: "#68AD3F" }} icon={false} severity="success">{success}</Alert>}
                {error && <Alert sx={{ color: "error" }} icon={false} severity="error">{error}</Alert>}
                <p className="auth-title-org">Reset Password</p>
                <Formik
                    initialValues={confirmPasswordInitialValues}
                    validationSchema={confirmPasswordValidationSchema}
                    onSubmit={confirmPasswordHandler}
                >
                    {() => {
                        return (
                            <Form autoComplete="off">
                                <div>
                                    <div className="d-flex flex-column ">
                                        <label className="label-head">Password: </label>
                                        <Field className="input-box" type="password" name="password" placeholder="Password" />
                                        <ErrorMessage className="auth-error" name="password" component="div" />
                                    </div>
                                    <div className="d-flex flex-column ">
                                        <label className="label-head">Confirm Password: </label>
                                        <Field className="input-box" type="password" name="repeatPassword" placeholder="Confirm Password" />
                                        <ErrorMessage className="auth-error" name="repeatPassword" component="div" />
                                    </div>
                                    <div className="d-flex flex-grow-1 justify-content-center mt-3">
                                        <Button className="filled">Confirm</Button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            <Help />
        </div>
    )
}

export default ConfirmPasswordOrg